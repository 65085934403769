<main class="jah__main jah__mainn background__wrapper">
  <h1 class="jah__Heading" *ngIf="hasPermission(['Manage Jobs Approval Hierarchy(Add/Edit)'])"> Job Approval Hierarchy </h1>
  <form #jahForm="ngForm" (ngSubmit)="submitJAH()" *ngIf="hasPermission(['Manage Jobs Approval Hierarchy(Add/Edit)'])">
    <div class="row mapp__hiringg__stagee">
      <div class="col-lg-4 col-12 mt-3 figma__ng__select">
        <label class="figma__label"> School <span class="mandiatory">*</span></label>
        <ng-select [items]="newbrandarray" placeholder="Select school" bindLabel="brandName" bindValue="brandId"
          [(ngModel)]="selectedJAHBrand" name="jahBrand" #jahBrand="ngModel" required [searchable]="true"
          (change)="GetJobsByBrandId($event.brandId)" [multiple]="false" [disabled]="editDisable">
        </ng-select>
        <div *ngIf="(jahBrand.touched || jahForm.submitted) &&jahBrand.errors?.required">
          <span class="errorMessage"> Please select school </span>
        </div>
      </div>
      <div class="col-lg-4 col-12 mt-3 figma__ng__select jah_tooltip">
        <div class="d-flex">
          <label class="figma__label"> Job Title<span class="mandiatory">*</span> </label>
          <div class="tooltip ttoooltipp absolute" style="left: 74px; top: -5px;">
            <svg width="12" height="16" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
            <span class="tooltiptext tootltip_stylz">
              If the approving hierarchy for a job tilte is different for two countries, please create approval
              hierarchy individually for each country
            </span>
          </div>
        </div>
        <ng-select class="jah__Job__title mt-1" [items]="jobTitle" placeholder="Select job title" bindLabel="job_Name"
          required bindValue="masterJobId" [(ngModel)]="selectedJAHJobTitle" name="jahJobTitle" #jahJobTitle="ngModel"
          [multiple]="true" [searchable]="true" (change)="levelup($event)" [disabled]="editDisable">
        </ng-select>
        <div *ngIf="(jahJobTitle.touched || jahForm.submitted) &&jahJobTitle.errors?.required">
          <span class="errorMessage">
            Please select job title
          </span>
        </div>
      </div>
      <div class="col-lg-4 col-12"></div>
      <div class="col-lg-4 col-12 mt-3 figma__ng__select country_jah_placeholder">
        <label class="figma__label d-flex align-items-center"> Country
          <span class="mandiatory">*</span>
        </label>
        <ng-select [items]="countryList" placeholder="Select country" bindLabel="countryName" bindValue="countryId"
          required [(ngModel)]="selectedJAHCountry" name="jahCountry" #jahCountry="ngModel" [multiple]="true"
          [searchable]="true" (change)="getCampusInfo($event)" [disabled]="editDisable">
        </ng-select>
        <div *ngIf="(jahCountry.touched || jahForm.submitted) &&jahCountry.errors?.required">
          <span class="errorMessage">
            Please select country
          </span>
        </div>
      </div>
      <div class="col-lg-4 col-12 mt-2 figma__ng__select jahh___campus country_jah_placeholder">
        <label class="figma__label"> Campus <span class="mandiatory">*</span></label>
        <ng-select [items]="campus_list" placeholder="Select campus" bindLabel="name" bindValue="campusId" required
          [(ngModel)]="selectedJAHCampus" name="jahCampus" #jahCampus="ngModel" [multiple]="true" [searchable]="true"
          (change)=campusValidate(selectedJAHCampus)>
        </ng-select>
        <div *ngIf="(jahCampus.touched || jahForm.submitted) && jahCampus.errors?.required">
          <span class="errorMessage">
            Please select campus
          </span>
        </div>
      </div>

      <!-- <div class="col-md-4 mt-3">
        <label class="figma__label"> Level </label>
        <input type="text" class="figma__Inputs col-md-12 px-0" readonly placeholder="Level" [(ngModel)]="jahLEvell"
          name="jahLeveell" #jahLeveell="ngModel">
        <div *ngIf="(jahLeveell.touched || jahForm.submitted) &&jahLeveell.errors?.required">
          <span class="errorMessage">
            Please enter level
          </span>
        </div>
      </div>
      <div class="col-md-4 mt-3">
        <label class="figma__label"> Sub Level </label>
        <input type="text" class="figma__Inputs col-md-12 px-0" readonly placeholder="Sub level"
          [(ngModel)]="jahSubbLEvell" name="jahSubbLeveell" #jahSubbLeveell="ngModel">
        <div *ngIf="(jahSubbLeveell.touched || jahForm.submitted) && jahSubbLeveell.errors?.required">
          <span class="errorMessage">
            Please enter sub level
          </span>
        </div>
      </div> -->

      <div class="col-lg-12 col-12 px-0 mt-3" *ngFor="let item of jahDataa.approverLists; let i = index">
        <div class="row mx-0">
          <div class="figma__ng__select col-lg-4 col-12">
            <label class="figma__label"> Approver Hierarchy<span class="mandiatory">*</span></label>
            <ng-select [items]="jobTitle" placeholder="Select approver" bindLabel="job_Name"
              bindValue="masterJobId" [(ngModel)]="item.jobTitleId" name="jahJobApprover{{i}}" #jahJobApprover="ngModel"
              required [multiple]="false" [searchable]="true">
            </ng-select>
            <div
              *ngIf="(jahJobApprover.touched || jahForm.submitted && approverError) &&jahJobApprover.errors?.required">
              <span class="errorMessage">
                Please select approver hierarchy
              </span>
            </div>
          </div>
          <div class="figma__ng__select col-lg-4 col-12">
            <label class="figma__label"> Approver's Name <span class="mandiatory">*</span></label>
            <ng-select [items]="approverList" placeholder="Select approver's name" bindLabel="userName"
              bindValue="userId" [(ngModel)]="item.userId" name="jahJobApproverName{{i}}" #jahJobApproverName="ngModel"
              required [multiple]="true" [searchable]="true">
            </ng-select>
            <div
              *ngIf="(jahJobApproverName.touched || jahForm.submitted && approverError) &&jahJobApproverName.errors?.required">
              <span class="errorMessage">
                Please select approver's name
              </span>
            </div>
          </div>
          <div class="col-lg-4 col-12 d-flex align-items-center" style="margin-top: 24px;">
            <span class="add__Text mr-4" *ngIf="JAHLevels"> Approver {{i+1}} </span>

            <span role="button" class="add__Text remove__Text" (click)="deleteFieldss(i)"
              *ngIf="(jahDataa.approverLists.length === ( i+1)) &&  (i !=0 )"
              style="color:rgba(254, 84, 84, 1) !important">
              Remove
            </span>
            <span role="button" class="add__Text" *ngIf="
            i == jahDataa.approverLists.length - 1 ||
            jahDataa.approverLists.length == 1
          " (click)="addFieldss(i)">
              <!-- <img style="width: 10px; height: 10px; margin-right: 13px;" src="../../assets/img/blue-plus-icon.svg"
                alt="blue plus" /> -->
              Add
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6 px-0 mt-3 d-flex" *ngFor="let item of jahDataa.approverLists; let i = index"> -->

      <!-- <div class="ml-2 col-md-4" style="margin-top: 32px;">
        <span role="button" class="add__Text" *ngIf="
            i == jahDataa.approverLists.length - 1 ||
            jahDataa.approverLists.length == 1
          " (click)="addFieldss(i)">
          <img style="width: 10px; height: 10px; margin-right: 13px;" src="../../assets/img/blue-plus-icon.svg"
            alt="blue plus" /> Add
        </span>
        <span role="button" class="add__Text remove__Text" (click)="deleteFieldss(i)"
          *ngIf="(jahDataa.approverLists.length === ( i+1)) &&  (i !=0 )" style="color:rgba(254, 84, 84, 1) !important">
          Remove
        </span>
      </div>
    </div> -->

      <div class="d-flex col-md-12 px-3 mt-5 justify-content-between">
        <div class="d-flex">
          <button type="button" class="discard_red_button fs16" (click)="jahhCancel()"> Cancel </button>

          <button type="submit" class="figma_background_blue_btn ml-3 jah__Submitt" style="padding:10px 30px !important"
            [disabled]="!jahForm.valid || isDisabled"> Submit </button>
          <!-- (click)="showJahPopup()" -->
        </div>

      </div>
    </div>
  </form>
  <div class="relative figma_search_part mt-5 d-flex justify-content-end">
    <!-- *ngIf="totalRecords > 0" -->
    <h1 class="jah__Heading"> Job Approval Hierarchy List </h1>
    <img class="absolute figma__search__Icon" src="{{appSettings.imageUrl}}/img/search-icon.svg" alt="search" />
    <input class="ml-auto" type="text"
    name="JAhhSearchBarr" minlength="2" (keyup)="onKeyUp()" placeholder="Search job title"
    [(ngModel)]="JahSearchh" #JAhhSearchBarr="ngModel" />
    <img class="job-searchh-new position-absolute" src="{{appSettings.imageUrl}}/img/figma-close-iconBlue-1.svg"
    (click)="removeValue()" alt="cross" *ngIf="blueCrossMark" />
    <!-- <input class="ml-auto" name="jshSearchh" placeholder="Search" [(ngModel)]="JahSearchh" name="JAhhSearchBarr"
      (keyup)="showCross()" (keyup)="jobSearch()" />
    <img class="absolute figma__search__Icon" src="{{appSettings.imageUrl}}/img/search-icon.svg" alt="search"
      style="right:408px !important; left:auto !important" />
    <img class="job-searchh-new position-absolute" src="{{appSettings.imageUrl}}/img/figma-close-iconBlue-1.svg"
      (click)="removeValue()" alt="cross" *ngIf="blueCrossMark" /> -->
  </div>
  <!-- *ngIf="hierarchy.length>=1" -->

  <div class="d-flex align-items-center justify-content-between">
  <h1 class="thirtyFive_entries my-3"> Displaying {{totalRecords}} Records </h1>
  <div class="d-flex align-items-center">
    <!-- <ul class="pagination d-flex ml-4 paggi-txt align-items-center" *ngIf="totalRecords > 0">

    <button [disabled]="paginationButtonDisabled" class="pagination-buttons"  style="line-height:0 !important" (click)="previous()"  *ngIf="totalRecords > 0 && v1 !== 1">
      <svg width="16" height="16" fill="#764fdb" class="bi bi-chevron-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
      </svg>
    </button>
    <li class="mx-2">
      {{ v1 }} - {{ v2 }} of <span>{{ totalRecords }}</span>
    </li>

    <button [disabled]="paginationButtonDisabled" class="pagination-buttons"  style="line-height:0 !important" (click)="next()" *ngIf="totalRecords > 0 && (totalRecords - v2)">
      <svg width="16" height="16" fill="#764fdb" class="bi bi-chevron-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    </button>
  </ul> -->
  </div>
  </div>

  <div class="loader jah---loaderr" *ngIf="showLoader">

  </div>
  <div *ngIf="!hierarchy.length && !showLoader">
    <p class="text-center" style="color: #764fdb !important; margin-top: 30px">
      No Records Found
    </p>
  </div>
  <div class="jah__divvv_table table-y-overflow table_scrollbar" *ngIf="(hierarchy).length > 0">
    <div class="table jah__Table__mainn div__table__main col-md-12 px-0"
      >
      <!-- *ngIf="(hierarchy | filter: JahSearchh).length > 0; else noResults" -->
      <div class="d-flex divTable__head z1" style="position: sticky !important; top: 0px !important;">
        <div class="cell">Sl.No</div>
        <div class="cell">School</div>
        <div class="cell">Country</div>
        <div class="cell">Campus</div>
        <div class="cell">Job Title</div>
        <div class="cell"><span class="approverNames__Span"> Approver's Name </span> </div>
        <!-- <div class="cell">Approver's Name</div> -->
        <div class="cell ml-5"></div>
      </div>
      <div style="border-bottom:16px solid white !important"></div>
      <!-- <div class="loader" *ngIf="showLoader"></div> -->
      <ng-container *ngFor="let x of hierarchy; let i = index; let last = last">
        <div class="d-flex row__bodyy">
          <div class="cell"> {{x.jahJobTitleId}} </div>
          <div class="cell"> {{x.brand}} </div>
          <!-- <div class="cell"> {{commaSeperated(x.country)}} </div> -->
          <div class="cell">
            <div *ngFor="let x of commaSeperated(x.country)">
              <span>{{x}}</span>
            </div>

          </div>
          <div class="cell">
            <ng-container *ngFor="let y of x.campus">
              <h6 class="p_font mt-1">{{y.campusName}}</h6>
            </ng-container>
          </div>
          <div class="cell">
            <!-- {{x.jobTitle}} -->
            <ng-container *ngFor="let y of commaSeperated(x.jobTitle)">
              <h6 class="p_font mt-1">{{y}}</h6>
            </ng-container>
          </div>
          <!-- <div class="cell">
          <div *ngFor="let x of commaSeperated(x.jobTitle)">
            <span>{{x}}</span>
            </div>

        </div> -->
          <div class="cell" style="width:436px !important">
            <div class="hover-text">
              <div class="d-flex hover_text_part align-items-center" *ngFor="let y of x.approverLists; let i=index;">
                <p class="" [innerHTML]="listUsers(y.user) +  listData"> </p>
                <!-- <p class="ml-2"> {{listData}} </p> -->
                <!-- <p style="width:600px !important">  <span style="width:200px !important"> {{listData}} </span> </p> -->
                <!-- <a class="approver__boxes tipped" (mouseenter)="showJobStatus(y)">
                Approver {{i+1}}
                  <ng-container *ngFor="let y of commaSeperated(y.user)"> {{y}} <br> </ng-container>

              </a> -->
                <a class="approver__boxes tipped" (mouseenter)="showJobStatus(y)">
                  Approver {{i+1}}
                  <span class="tooltiptextedd" tooltipPosition="bottom" style="margin-left: -230px !important;">
                    <ng-container *ngFor="let y of commaSeperated(y.user)">
                      <div [innerHTML]="y"> </div>
                    </ng-container>
                  </span>
                </a>
              </div>
              <!-- <div class="refCheck_border_bottom_line my-2"></div> -->
            </div>
          </div>
          <!-- {{y.approverHierarchy}}.......... -->
          <!-- <span class="">
          <span class="approver__boxes"> Approver 1 </span>  <br>
          {{x.jobTitle}}({{x.level}} - {{x.subLevel}}) </span> <br>
          Approver 2 <br>
          <span>   {{x.jobTitle}}({{x.level}} - {{x.subLevel}}) </span> -->
          <!-- </div> -->
          <!-- <p class="mt-1" style="font-size: 14px !important;"> Level 1 </p> -->



          <!-- <div class="cell"> -->
          <!-- <div class="hover-text" *ngFor="let y of x.approverLists; let i=index;">
            <a class="approver__boxes tipped" (mouseenter)="showJobStatus(y)">
              Approver {{i+1}}
              <span class="tooltiptextedd">
                <ng-container *ngFor="let y of commaSeperated(y.user)"> {{y}} </ng-container>
              </span>
            </a>
          </div> -->
          <!-- <span style="font-size: 10px !important;"> (hover to see list of Approvers) </span> -->
          <!-- <span class="tooltip-text" id="bottom">
               <p class="my-1"> uday - uday@codebele.com </p>
               <p class="my-1"> Manoj - manoj@codebele.com </p>
               <p class="my-1"> Shashipal - shashipal@codebele.com </p>

            </span> -->
          <!-- </div> -->

          <!-- <div class="cell">

          <div class="hover-text">
            <p class="approvers_tool__tip">  Approver 1 </p>
            <p class="approvers_tool__tip">  Approver 2 </p>
            <p class="approvers_tool__tip">  Approver 3 </p>
            <span class="tooltip-text" id="bottom">
               <p class="my-1"> uday - uday@codebele.com </p>
               <p class="my-1"> Manoj - manoj@codebele.com </p>
               <p class="my-1"> Shashipal - shashipal@codebele.com </p>

            </span>
          </div>
          <div class="cell" *ngFor="let y of x.approverLists">{{y.jobTitle}}</div>

        </div> -->
          <div class="cell ml-5 relative">
            <button class="dot-info-wrp ml-auto three-dots-button" (click)="showAllKebabMenu(i)" >
              <img class="pointer" src="{{appSettings.imageUrl}}/img/blue-three-dots.svg"
                alt="3 dots" />

              <div class="background__wrapper absolute all__kebab__menuu" id="allKeebabbMenuu{{i}}"
                (mouseleave)="noAllKebabMenu(i)" style="display:none; right: -30px;
              top: 0px !important; width: 150px !important;">
                <ul>
                  <button class="jah-menu-list-button" (click)="editJahbyId(x.jobApprovalHierarchyId)" *ngIf="x.status==true">
                    <!-- <svg
                      xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
                      class="bi bi-pencil mr-3" viewBox="0 0 16 16">
                      <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg> -->
                    <img src="{{this.appSettings.imageUrl}}/img/Rish/edit-job.svg" alt="" style="width: 16px; height:16px !important;">
                    Edit
                  </button>
                  <button class="jah-menu-list-button d-flex align-items-center" *ngIf="x.status==true" [disabled]="deActivateButton"
                    (click)="activeJahbyId(x.jobApprovalHierarchyId,false)">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg"
                      width="14" height="14" fill="currentColor" class="bi bi-eye mr-3" viewBox="0 0 16 16">
                      <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path
                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>  -->
                    <img src="{{this.appSettings.imageUrl}}/img/Rish/Inactive.svg" alt="in activate" style="width: 16px; height:16px !important;"/>
                    <div class="loader" *ngIf="showLoadeerr">

                    </div>
                    <span role="button" style="color:red; font-size: 13px !important;">De-Activate</span>
                  </button>
                  <button class="jah-menu-list-button d-flex align-items-center" *ngIf="x.status!=true" [disabled]="deActivateButton"
                    (click)="activeJahbyId(x.jobApprovalHierarchyId,true)">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg"
                      width="12" height="12" fill="currentColor" class="bi bi-x-lg mr-3" viewBox="0 0 16 16">
                      <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                    </svg>  -->
                    <img src="{{this.appSettings.imageUrl}}/img/Rish/Active.svg" alt="">
                    <div class="loader" *ngIf="showLoadeerr">

                    </div>
                    <span role="button" style="color:rgb(0, 172, 11); font-size: 13px !important;"> Activate </span>
                  </button>
                </ul>
              </div>
            </button>

          </div>
        </div>
        <div class="figma__border__bottom"></div>
      </ng-container>
    </div>

  </div>

  <div class="tr-paginator" *ngIf="hierarchy.length && !showLoader">
    <div [attr.colspan]="7">
      <p-paginator
        [first]="first"
        [rows]="itemsPerPageActive"
        [totalRecords]="totalRecordsActive"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        (onPageChange)="onPageChangeActive($event)"
      >
      </p-paginator>
    </div>
  </div>


  <!-- <div *ngIf="hierarchy.length == 0">
    <p class="text-center" *ngIf="showRecods" style="color: #764FDB !important;margin-top: 30px;">
      No Records Found</p>
    </div> -->
  <!-- </div> -->

  <!-- default table in 1280px starts here -->
  <div class="jah__default__table">
    <div class="loader" *ngIf="showLoader">

    </div>

    <div class="row d-flex justify-content-center">
    <table class="figma__Table_styles figma__Table_styles_manage col-md-12 px-0 table-responsive" style="overflow: scroll; height: 555px;"
      *ngIf="(hierarchy ).length > 0; else nnoResults">
      <thead class="table__Header__Sticky" style="top:-1px !important">
        <tr class="table__Header__Sticky">
          <th style="
                      min-width: 100px !important;
                      width: 100px !important;">
            Sl.No
          </th>
          <th style="min-width: 165px !important;">
            School
          </th>
          <th style="min-width: 165px !important;">
            Country
          </th>
          <th style="min-width: 165px !important;">
            Campus
          </th>
          <th style="min-width: 200px !important;">
            Job Title
          </th>
          <th style="min-width: 200px !important;">
            <span class="approverNames__Span"> Approver's Name </span>
          </th>
          <th style="min-width: 312px !important;"></th>
        </tr>
      </thead>
      <div style="border-bottom:16px solid white"></div>
      <tbody class="">
        <ng-container *ngFor="let x of hierarchy; let i = index">
          <tr class="new-hoverR position-relative">
            <td class="" style="min-width: 100px !important; width: 100px !important;">
              {{x.jahJobTitleId}}
            </td>
            <td class="" style="min-width: 165px !important; width: 165px !important;"> {{x.brand}} </td>
            <td class="" style="min-width: 165px !important; width: 165px !important;">
              <div *ngFor="let x of commaSeperated(x.country)">
                <span>{{x}}</span>
              </div>
            </td>
            <td class="" style="min-width: 165px !important; width: 165px !important;">
              <ng-container *ngFor="let y of x.campus">
                <h6 class="p_font mt-1">{{y.campusName}}</h6>
              </ng-container>
            </td>
            <td class="" style="min-width: 200px !important; width: 165px !important;">
              <ng-container *ngFor="let y of commaSeperated(x.jobTitle)">
                <h6 class="p_font mt-1">{{y}}</h6>
              </ng-container>
            </td>
            <!-- <td style="width: 200px; min-width: 200px;">
              <h6 class="p_font mt-1">qwertyu</h6>
            </td> -->
            <td class="" style="min-width: 465px !important; width: 465px !important;">
              <div class="hover-text">
                <div class="d-flex hover_text_part align-items-center" *ngFor="let y of x.approverLists; let i=index;">
                  <div style="width: 250px !important; overflow: hidden" [innerHTML]="listUsers(y.user)"></div>
                  <!-- <a class="approver__boxes tipped" (mouseenter)="showJobStatus(y)">
                              Approver {{i+1}}
                                <ng-container *ngFor="let y of commaSeperated(y.user)"> {{y}} <br> </ng-container>

                            </a> -->
                  <a class="approver__boxes tipped ml-auto" (mouseenter)="showJobStatus(y)">
                    Approver {{i+1}}
                    <span class="tooltiptextedd tooltiptexteddddd">
                      <ng-container *ngFor="let y of commaSeperated(y.user)">
                        <div [innerHTML]="y"> </div>
                      </ng-container>
                    </span>
                  </a>
                </div>
                <!-- <div class="refCheck_border_bottom_line my-2"></div> -->
              </div>
            </td>
            <td class="min-width:200px !important; width:200px !important;">
              <button class="dot-info-wrp three-dots-button ml-auto" (click)="showAllKebabMenuu(i)">
                <img class="pointer"
                  src="{{appSettings.imageUrl}}/img/blue-three-dots.svg" alt="3 dots" />

                <div class="background__wrapper absolute all__kebab__menuu" id="aallKeebabbMenuu{{i}}"
                  (mouseleave)="noAllKebabMenuu(i)">
                            <ul>
                              <button class="pointer jah-menu-list-button" (click)="editJahbyId(x.jobApprovalHierarchyId)" *ngIf="x.status==true">
                                <!-- <svg
                                  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
                                  class="bi bi-pencil mr-3" viewBox="0 0 16 16">
                                  <path
                                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                </svg> -->
                                <img src="{{this.appSettings.imageUrl}}/img/Rish/edit-job.svg" alt="" style="width: 16px; height:16px;">
                                Edit
                              </button>
                              <button class="pointer d-flex align-items-center jah-menu-list-button" *ngIf="x.status==true" [disabled]="deActivateButton"
                                (click)="activeJahbyId(x.jobApprovalHierarchyId,false)">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg"
                                  width="14" height="14" fill="currentColor" class="bi bi-eye mr-3" viewBox="0 0 16 16">
                                  <path
                                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                  <path
                                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>  -->
                                <img src="{{this.appSettings.imageUrl}}/img/Rish/Inactive.svg" alt="in activate" style="width: 16px; height:16px;" />
                                <div class="loader" *ngIf="showLoadeerr">

                                </div>
                                <span role="button" style="color:red; font-size: 13px !important;">De-Activate</span>
                              </button>
                              <button class="pointer d-flex align-items-center jah-menu-list-button" *ngIf="x.status!=true" [disabled]="deActivateButton"
                                (click)="activeJahbyId(x.jobApprovalHierarchyId,true)">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg"
                                  width="12" height="12" fill="currentColor" class="bi bi-x-lg mr-3" viewBox="0 0 16 16">
                                  <path
                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>  -->
                                <img src="{{this.appSettings.imageUrl}}/img/Rish/Active.svg" alt="">
                                <div class="loader" *ngIf="showLoadeerr">

                                </div>
                                <span role="button" style="color:rgb(0, 172, 11); font-size: 13px !important;"> Activate </span>
                              </button>
                            </ul>
                </div>
              </button>
              <div class="loader" *ngIf="showLoadeerr">

              </div>
            </td>
          </tr>
          <div class="figma__border__bottom"></div>
        </ng-container>
      </tbody>
    </table>
  </div>
    <ng-template #nnoResults>
      <p class="text-center" *ngIf="showRecods" style="color: #764FDB !important;margin-top: 30px;">
        No Records Found</p>
    </ng-template>
  </div>
  <!-- defalut table in 1280px ends here -->







  <!-- <div>
    <span *ngFor="let word of words; let last = last">
      {{ word }}
      <br *ngIf="!last" />
    </span>
  </div> -->

  <div *ngIf="JahSubmitMOdal" id="addApplicantModalMain" class="addApplicant__modal_main z9999">
    <div class="addApplicant__mod__content"
      style="height: fit-content !important; width:700px !important; left:50% !important; transform: translate(-50%);">
      <div class="modal__heading">
        <h5 class="add__app__heading" id="addApplicantTitle" style="margin-bottom:20px !important">
          Alert<span style="color: red;">*</span>
        </h5>
        <!-- <button type="button" class="close new_figma_blue_btn figma__close relative ml-auto" (click)="JAHModalClose()">
          <img class="absolute" src="{{ appSettings.imageUrl }}/img/blue-close-icon.svg" alt="close" />
        </button> -->
        <div>
          Approval Hierarchy has already been set for <span class="fw600">{{jobTitleName}} </span> for Campus -<span
            class="fw600">{{popup}} </span>
        </div>
        <!-- <span class="select_option_span">Please remove the existing Job title </span> -->
        <div class="add__bulk__buttons d-flex">
          <button type="button" class="p12-20 figma_background_blue_btn d-flex mx-auto" (click)="JAHModalClose()"> Okay
          </button>
          <!-- <button type="button" class="btn add__App__btnn figma_background_blue_btn" data-dismiss="modal"
          aria-label="Close" (click)="newAppShown()">Close</button> -->
          <!-- <button type="button" data-dismiss="modal" aria-label="Close" class="btn bulK__Upload__btnn figma_blue_button"
          (click)="bulkUploadScreenVisible()">
          Bulk Upload
        </button> -->
        </div>
      </div>
    </div>
  </div>

</main>
