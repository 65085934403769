<section class="trfmain background__wrapper" style="overflow-x: hidden">
  <ng-container *ngIf="trfMain">
    <div
      class="d-flex align-items-center justify-content-between mb-4"
      style="flex-wrap: wrap; row-gap: 8px"
    >
      <div class="">
        <h1
          class="head-h1 requisition__Heading jah_tooltip trff_tooltip position-relative"
          id="page-main-heading"
        >
          Job Requisition
          <div
            class="tooltip ttoooltipp tootltip_top align-self-center"
            id="trf-tooltip-main"
          >
            <svg
              width="14"
              height="14 "
              fill="currentColor"
              class="bi bi-info-circle absolute pointer"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
              />
            </svg>
            <span class="tooltiptext trf_tooltiptext" id="trf-tooltip">
              <p>
                1.Job Requisition ID will remain in pending status upto 7 Days
                and will change to expired.
              </p>
              <p>
                2.Job Requisition ID status will automatically change to
                <span class="fw600">"Accepted"</span> when a Job is created for
                the respective TR ID
              </p>
              <p>
                3.ERROR CODE -<span class="fw600">ERJAH401 </span>The approval
                hierarchy has not been set for one or more campus
              </p>
            </span>
          </div>
        </h1>
      </div>
      <div class="d-flex" style="gap: 10px">
        <div class="figma_search_part ml-auto d-flex align-items-center">
          <!-- <h1 class="thirtyFive_entries mr-4" style="margin-bottom: 0 !important">
            Displaying {{ totalRecords }} Records
          </h1> -->
          <div class="justify-content-end d-flex relative">
            <input
              type="text"
              id="applicants-search-new"
              name="interviewSearch"
              placeholder="Search"
              [(ngModel)]="trfSearch"
              (keyup)="onKeyUp(); showCross()"
            />
            <!-- (keyup)="showCross()" -->
            <!-- <img
              class="absolute trfSearch__iconn"
              src="{{ appSettings.imageUrl }}/img/search-icon.svg"
              alt="search"
            /> -->
            <img
              class="absolute trfSearch__iconn"
              src="./assets/img/search-icon.svg"
              alt="search"
            />
            <img
              class="job-search-new pointer absolute"
              id="cross-icon"
              src="./assets/img/figma-close-iconBlue-1.svg"
              (click)="removeValue()"
              alt="cross"
              *ngIf="blueCrossMark"
            />
            <!-- <img
              class="job-search-new pointer absolute"
              id="cross-icon"
              src="{{ this.appSettings.imageUrl }}/img/figma-close-iconBlue-1.svg"
              (click)="removeValue()"
              alt="cross"
              *ngIf="blueCrossMark"
            /> -->
          </div>
          <!-- <div class="d-flex align-items-center">
            <ul
              class="pagination d-flex ml-2 paggi-txt align-items-center"
              *ngIf="totalRecords > 0"
            >
              <button [disabled]="paginationButtonDisabled"
                class="pagination-buttons"
                style="line-height: 0 !important"
                (click)="previous()"
                *ngIf="totalRecords > 0 && v1 !== 1"
              >
                <svg
                  width="16"
                  height="16"
                  fill="#764fdb"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </button>
              <li class="mx-2">
                {{ v1 }} - {{ v2 }} of <span>{{ totalRecords }}</span>
              </li>

              <button [disabled]="paginationButtonDisabled"
                class="pagination-buttons"
                style="line-height: 0 !important"
                (click)="next()"
                *ngIf="totalRecords > 0 && totalRecords - v2"
              >
                <svg
                  width="16"
                  height="16"
                  fill="#764fdb"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </button>
            </ul>
          </div> -->
          <!-- <button role="button" class="secondary-btn secondary-btn-mobile btn" (click)="openTRFForm()"> Add TR </button> -->
          <!-- <button *ngIf="hasPermission(['TR Hr(Add/Edit)'])" -->
        </div>
        <!-- {{hasPermission(['View TR Manager'])}} -->
        <!-- *ngIf="roleStatus" -->
        <div class="chart-head">
          <ul
            class="nav nav-tabs"
            id="roleTab"
            role="tablist"
            *ngIf="
              hasPermission([
                'Add/Edit TR HR',
                'View TR HR',
                'View TR Manager',
                'Add/Edit TR Manager',
                'Approve TR Manager',
                'Reject TR Manager'
              ])
            "
          >
            <li
              class="nav-item"
              role="presentation"
              *ngIf="hasPermission(['Add/Edit TR HR', 'View TR HR'])"
            >
              <button
                class="nav-link"
                [ngClass]="{ active: selectedRole === 'HR' }"
                (click)="setRole('HR')"
                id="hr-tab"
                type="button"
                role="tab"
                aria-controls="hr"
                [disabled]="tabsButtonDisabled"
              >
                HR
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
              *ngIf="
                hasPermission([
                  'View TR Manager',
                  'Add/Edit TR Manager',
                  'Approve TR Manager',
                  'Reject TR Manager'
                ])
              "
            >
              <button
                class="nav-link"
                [ngClass]="{ active: selectedRole === 'Manager' }"
                (click)="setRole('Manager')"
                id="manager-tab"
                type="button"
                role="tab"
                aria-controls="manager"
                [disabled]="tabsButtonDisabled"
              >
                Manager
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!-- (click)="openNewTab('/trf-form-two')" -->
    </div>

    <!-- <ul class="nav nav-tabs trfTabs__mainn mt-4 d-flex" id="myTab" role="tablist">
    <li class="nav-item pointer all_nav__items" role="presentation">
      <button class="nav-link all__nav_link active pointer" id="All-tab" data-toggle="tab" data-target="#All"
        type="button" role="tab" aria-controls="All" aria-selected="true">All</button>
    </li>

  </ul> -->

    <div
      class="d-flex justify-content-between align-items-center flex-wrap mtr_tp"
    >
      <ul
        class="nav nav-tabs trfTabs__mainn table_scrollbar"
        id="myTab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            class="fs12 pointer nav-link ml-0 active"
            id="pending-tab"
            data-toggle="tab"
            data-target="#pending"
            type="button"
            role="tab"
            aria-controls="pending"
            [class.active]="navLinkActive === 'pending'"
            aria-selected="true"
            (click)="setActiveTab('pending'); getDataOfTrf(0)"
          >
            Pending
            <p class="candidiate-notification-numberr fw600">
              {{ allTRFCount.pending }}
            </p>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link pointer fs12"
            id="accepted-tab"
            data-toggle="tab"
            data-target="#accepted"
            type="button"
            role="tab"
            aria-controls="accepted"
            [class.active]="navLinkActive === 'accepted'"
            aria-selected="false"
            (click)="setActiveTab('accepted'); getDataOfTrf(1)"
          >
            Accepted
            <p class="candidiate-notification-numberr fw600">
              {{ allTRFCount.accepted }}
            </p>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link pointer fs12"
            id="rejected-tab"
            data-toggle="tab"
            data-target="#rejected"
            type="button"
            role="tab"
            aria-controls="rejected"
            [class.active]="navLinkActive === 'rejected'"
            aria-selected="false"
            (click)="setActiveTab('rejected'); getDataOfTrf(2)"
          >
            Rejected
            <p class="candidiate-notification-numberr fw600">
              {{ allTRFCount.rejected }}
            </p>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link pointer fs12"
            id="expired-tab"
            data-toggle="tab"
            data-target="#expired"
            type="button"
            role="tab"
            aria-controls="expired"
            [class.active]="navLinkActive === 'expired'"
            aria-selected="false"
            (click)="setActiveTab('expired'); getDataOfTrf(3)"
          >
            Expired
            <p class="candidiate-notification-numberr fw600">
              {{ allTRFCount.expired }}
            </p>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link pointer fs12"
            id="jobRejected-tab"
            data-toggle="tab"
            data-target="#jobRejected"
            type="button"
            role="tab"
            aria-controls="jobRejected"
            [class.active]="navLinkActive === 'jobRejected'"
            aria-selected="false"
            (click)="setActiveTab('jobRejected'); getDataOfTrf(4)"
          >
            Job Rejected
            <p class="candidiate-notification-numberr fw600">
              {{ allTRFCount.jobRejected }}
            </p>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link pointer fs12"
            id="jobClosed-tab"
            data-toggle="tab"
            data-target="#jobClosed"
            type="button"
            role="tab"
            aria-controls="jobClosed"
            [class.active]="navLinkActive === 'jobClosed'"
            aria-selected="false"
            (click)="setActiveTab('jobClosed'); getDataOfTrf(5)"
          >
            Job Closed
            <p class="candidiate-notification-numberr fw600">
              {{ allTRFCount.jobClosed }}
            </p>
          </button>
        </li>
      </ul>

      <button
        *ngIf="hasPermission(['Add/Edit TR HR'])"
        class="figma_background_blue_btn add_tr_btn"
        (click)="openTRFForm()"
      >
        Add Job Requisition
        <!-- <img
                class=""
                src="./assets/img/blue-plus--icon.svg"
                alt="plus"
              /> -->
        <!-- <img
                class=""
                src="{{ appSettings.imageUrl }}/img/blue-plus--icon.svg"
                alt="plus"
              /> -->
      </button>
    </div>
    <!-- <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="pending"
        role="tabpanel"
        aria-labelledby="pending-tab"
      >
        Pending
      </div>
      <div
        class="tab-pane fade"
        id="accepted"
        role="tabpanel"
        aria-labelledby="accepted-tab"
      >
        Accepted
      </div>
      <div
        class="tab-pane fade"
        id="rejected"
        role="tabpanel"
        aria-labelledby="rejected-tab"
      >
      rejected
      </div>
      <div
        class="tab-pane fade"
        id="expired"
        role="tabpanel"
        aria-labelledby="expired-tab"
      >
      Expired
      </div>
      <div
        class="tab-pane fade"
        id="jobRejected"
        role="tabpanel"
        aria-labelledby="jobRejected-tab"
      >
      job Rejected
      </div>
      <div
        class="tab-pane fade"
        id="jobClosed"
        role="tabpanel"
        aria-labelledby="jobClosed-tab"
      >
      job Closed
      </div>
    </div> -->

    <div class="tab-content" id="myTabContent mt-4">
      <div
        class="tab-pane fade show active table__overflow trf-table-overflow-main table___scrollbar mt-3"
        id="All"
        role="tabpanel"
        aria-labelledby="All-tab"
        style="height: 556px; overflow: scroll"
      >
        <div class="loader" *ngIf="loader"></div>

        <table
          class="figma__Table_styles figma__Table_styles_manage col-md-12 mt-2 px-0"
          *ngIf="trfData?.length > 0; else noResults"
        >
          <thead class="table__Header__Sticky z99" style="top: 0px !important">
            <tr class="table__Header__Sticky">
              <th class=" ">TRF ID</th>
              <th class="">Job Title</th>
              <th class="">Campus</th>
              <th class="">Category</th>
              <th class="" id="created-small">Created</th>
              <th class="">Requester</th>
              <th class="text-center">Vacancies</th>
              <th class="">Status</th>
              <th *ngIf="hasPermission(['Add/Edit TR HR'])"></th>
            </tr>
          </thead>
          <tbody class="">
            <ng-container *ngFor="let x of trfData; let i = index">
              <tr class="new-hoverR relative">
                <td
                  class=""
                  style="min-width: 100px !important; width: 130px !important"
                >
                  {{ x.trfid }}
                </td>
                <td>
                  <ng-container *ngFor="let x of commaSeperated(x.jahJobName)">
                    <p
                      class="fw600 blue-color-trf underline text-overflow"
                      style="width: fit-content"
                      pTooltip="{{ dataType(x) }}"
                      tooltipPosition="bottom"
                      tooltipStyleClass="custom-tooltip"
                    >
                      {{ x }}
                    </p>
                  </ng-container>
                  <div class="d-flex align-items-center mt-2">
                    <div class="trf__location">
                      <div id="jobs-brand-two" class="jobss__brandd t-w">
                        {{ x.brand }}
                      </div>
                    </div>

                    <div class="trf__location ml-3 text-overflow">
                      <span>
                        <img
                          class="mr-1"
                          src="./assets/img/view-jobs/locatioin-icon.svg"
                          alt="location"
                        />
                        <!-- <img
                          class="mr-1"
                          src="{{
                            this.appSettings.imageUrl
                          }}/img/view-jobs/locatioin-icon.svg"
                          alt="location"
                        /> -->
                        {{ x.country }}
                      </span>
                    </div>
                  </div>
                </td>
                <td
                  class=""
                  style="min-width: 50px !important; width: 150px !important"
                >
                  <div *ngFor="let y of commaSeperated(x.campusNames)">
                    {{ y }}
                  </div>
                </td>
                <td
                  class=""
                  style="min-width: 50px !important; width: 150px !important"
                >
                  {{ x.category }}
                </td>
                <td
                  class=""
                  style="
                    min-width: 50px !important;
                    width: 150px !important;
                    position: relative;
                  "
                >
                  {{ getJobDate(x.requestedDate) }}
                  <br />
                  <p
                    *ngIf="x.talentRequisitionStatus != null"
                    class="fw600 jobss__brandd t-w fs12"
                    role="button"
                    (click)="toggleModal(x.trfid)"
                    style="
                      color: blueviolet;
                      cursor: pointer;
                      margin-top: 10px;
                      width: fit-content;
                    "
                  >
                    {{ x.talentRequisitionStatus }}
                  </p>
                </td>
                <td
                  class=""
                  style="min-width: 50px !important; width: 200px !important"
                >
                  {{ x.requester }}
                </td>
                <td class="text-center" style="min-width: 50px !important">
                  <span>{{ x.numberOfPositions }}</span>
                </td>
                <td
                  style="
                    min-width: 50px !important;
                    width: 150px !important;
                    text-wrap: nowrap;
                  "
                >
                  {{ x.trfStatus }}
                </td>
                <td *ngIf="hasPermission(['Add/Edit TR HR'])">
                  <button
                    class="ml-auto three-dots-button dots position-relative"
                    (click)="showAllKebabMenu(i)"
                  >
                    <!--  *ngIf="!x.talentRequisitionStatus" -->
                    <!-- <img
                      class="pointer"
                      src="{{ appSettings.imageUrl }}/img/blue-three-dots.svg"
                      alt="3 dots"
                    /> -->
                    <img
                      class="pointer"
                      src="./assets/img/blue-three-dots.svg"
                      alt="3 dots"
                    />

                    <div
                      class="background__wrapper absolute all__kebab__menuu"
                      id="allKeebabbMenuu{{ i }}"
                      (mouseleave)="noAllKebabMenu(i)"
                      style="display: none"
                    >
                      <ul>
                        <li
                          class="pointer"
                          (click)="editTrf(x.talentId)"
                          *ngIf="
                            x.trfStatus === 'Pending' &&
                            hasPermission(['Add/Edit TR HR'])
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            class="bi bi-pencil mr-3"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                            />
                          </svg>
                          Edit
                        </li>
                        <li
                          class="pointer d-flex align-items-center"
                          (click)="viewTrf(x.talentId)"
                        >
                          <svg
                            width="14"
                            height="14"
                            fill="currentColor"
                            class="bi bi-eye mr-3"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                            />
                            <path
                              d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                            />
                          </svg>
                          View
                        </li>
                        <li
                          class="pointer d-flex align-items-center"
                          *ngIf="x.trfStatus === 'Pending'"
                          (click)="rejectTrf(x.talentId, 'Rejected')"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            class="bi bi-x-lg mr-3"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
                            />
                          </svg>
                          Reject
                        </li>
                      </ul>
                    </div>
                  </button>
                  <!-- not required while uncommenting old code(ALL tabs code) -->
                  <!-- <img class="edit-icon" style="height:22px;width:22px;"
                          src="{{appSettings.imageUrl}}/img/view-jobs/viewicon1.png" alt="" /> -->
                  <!-- <img class="edit-icon" style="height:22px;width:22px;"
                          src="{{appSettings.imageUrl}}/img/view-jobs/edit.svg" alt="" /> -->
                  <!-- <li class="pointer"> <img class="close-icon" style="height:22px;width:22px;"
                          src="{{appSettings.imageUrl}}/img/view-jobs/close.svg" alt="" />Close Job</li>
                      <li class="pointer"> <img class="close-icon" style="height:22px;width:22px;"
                          src="{{appSettings.imageUrl}}/img/view-jobs/close.svg" alt="" />UnPublish</li>
                      <li class="pointer"><img class="close-icon" style="height:22px;width:22px;"
                          src="{{appSettings.imageUrl}}/img/view-jobs/close.svg" alt="" />Bulk Upload</li> -->
                  <!-- not required while uncommenting old code(ALL tabs code) -->
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div class="tr-paginator">
        <div [attr.colspan]="7">
          <p-paginator
            [first]="first"
            [rows]="itemsPerPageActive"
            [totalRecords]="totalRecordsActive"
            [rowsPerPageOptions]="[10, 25, 50]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            (onPageChange)="onPageChangeActive($event)"
          >
          </p-paginator>
        </div>
      </div>

      <ng-template #noResults>
        <p
          *ngIf="!loader"
          class="text-center"
          style="color: #764fdb !important; margin-top: 30px"
        >
          No Records Found
        </p>
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="addTrfMain">
    <a
      class="fw600 pointer"
      (click)="backToTRF()"
      style="color: #764fdb !important"
      ><span class="mb-3"
        ><i class="icon-angle-down angle-rotate-d"></i>Back to Req</span
      ></a
    >

    <div class="trf_form_two_mainn d-flex mt-4">
      <div class="d-flex trf_1024" style="row-gap: 12px">
        <div class="trf_side__navv">
          <aside class="js-side-filter sticky-left trf__sticky">
            <div
              class="gs-side-filter gs-side-filter--sm contenT_Main trf_form__aside"
            >
              <ul class="gs-side-filter__list gs-long-list" id="progressbar">
                <li
                  class="gs-side-filter__list__item active gs-long-list__item"
                  style="cursor: pointer; width: fit-content"
                  (click)="respectiveScreen('basicinfo')"
                  [ngClass]="{ check: rafBasicCheck, active: rafBasicActive }"
                >
                  <div
                    class="gs-side-filter__link gs-side-filter__link--sm new__side__filter"
                  >
                    <span class="new__side__Label">Basic Info</span>
                  </div>
                </li>
                <li
                  class="gs-side-filter__list__item active gs-long-list__item"
                  style="cursor: pointer; width: fit-content"
                  (click)="respectiveScreen('description')"
                  [ngClass]="{ check: rafDescCheck, active: rafDescActive }"
                >
                  <div class="gs-side-filter__link gs-side-filter__link--sm">
                    <span class="new__side__Label">Job Description</span>
                  </div>
                </li>
              </ul>
            </div>
          </aside>
        </div>

        <div class="raf__twoo__mainn_part background__wrapper">
          <form novalidate #basicInfoo="ngForm">
            <div *ngIf="rafJobTitle">
              <div class="raf_Two_job__title_main">
                <div class="d-flex align-items-baseline">
                  <h1 class="head-h1 px-0 col-md-8">Job Requisition Form</h1>
                  <!-- <p class="figma__label px-0 col-md-4 pl-3 figma_blue_color"> Total Budgeted : </p> -->
                </div>
                <div class="row mt-3">
                  <div class="col-md-8 figma__ng__select">
                    <label for="icon" class="figma__label my-3"
                      >Requester<span class="mandiatory">*</span></label
                    >
                    <ng-select
                      class=""
                      [items]="trfrequesterArray"
                      (change)="
                        GetBrandDetailsByUserId(talentRequisition.requester)
                      "
                      bindLabel="userName"
                      bindValue="userId"
                      placeholder="Select requester"
                      [(ngModel)]="talentRequisition.requester"
                      name="requester"
                      #requester="ngModel"
                      [disabled]="viewDisable ? 'true' : 'false'"
                      required
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (requester.touched || basicInfoo.submitted) &&
                        requester.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please select requester
                      </span>
                    </div>
                  </div>

                  <!-- <div class="col-md-4 figma__ng__select"> -->
                  <!-- <label for="icon" class="figma__label my-3">Priority<span class="mandiatory">*</span> </label>
                <div class="col-md-12 d-flex yes__No__radios yes__No__radios_Two pl-0">
                  <p class="d-flex">
                    <input class="pointer input__Accent" type="radio" id="declare1" name="declareOne" value="Highpriority"
                      [(ngModel)]="talentRequisition.priority">
                    <label for="declare1" class="figma__label pl-2 pt-1">High Priority</label>
                  </p>
                  <p class="ml-5 d-flex">
                    <input class="pointer input__Accent" type="radio" id="declare2" name="declareOne" value="Normalpriority"
                      [(ngModel)]="talentRequisition.priority">
                    <label for="declare2" class="figma__label pl-2 pt-1">Medium Priority</label>
                  </p>
                </div> -->
                  <!-- </div> -->

                  <div class="col-md-12">
                    <label class="figma__label my-5">
                      Raising job Requisition for ?
                    </label>
                  </div>

                  <!-- <div class="col-md-4 figma__ng__select">
                <label for="icon" class="figma__label my-3">Job Title<span class="mandiatory">*</span></label>
                <ng-select class="" [items]="hierarchy_job" bindLabel="title" bindValue="jobApprovalHierarchyId" required
                  [(ngModel)]="talentRequisition.jahJobTitleId" name="jobTitle" #jobTitle="ngModel"
                  placeholder="Select job title" [multiple]="false" (change)="jobTrf($event)">
                </ng-select>
                <div *ngIf="(jobTitle.touched || basicInfoo.submitted) &&jobTitle.errors?.required">
                  <span class="errorMessage">
                    Please select job title
                  </span>
                </div>
              </div> -->
                  <div class="col-lg-4 col-6 figma__ng__select">
                    <label for="icon" class="figma__label my-3"
                      >School<span class="mandiatory">*</span></label
                    >
                    <ng-select
                      class=""
                      [items]="brandsnewarray"
                      bindLabel="brandName"
                      bindValue="brandId"
                      placeholder="Select school"
                      [(ngModel)]="talentRequisition.brand"
                      name="brand"
                      #brand="ngModel"
                      required
                      [multiple]="false"
                      (change)="getcountries(talentRequisition.brand)"
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </ng-select>
                    <!-- <input type="text" class="col-md-12 figma__Inputs px-0 trf_form_input dependent_color_field" placeholder="Brand" [(ngModel)]="talentRequisition.brand"
                  name="brand" #brand="ngModel" readonly /> -->
                    <!-- <ng-select class="" [items]="addTalentReqArray" bindLabel="name" bindValue="brandId"
                  placeholder="Select brand" [(ngModel)]="talentRequisition.brand" name="brand" #brand="ngModel" required>
                </ng-select> -->

                    <div
                      *ngIf="
                        (brand.touched || basicInfoo.submitted) &&
                        brand.errors?.required
                      "
                    >
                      <span class="errorMessage"> Please select school </span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-6 figma__ng__select">
                    <label for="icon" class="figma__label my-3"
                      >Country<span class="mandiatory">*</span></label
                    >
                    <ng-select
                      class=""
                      [items]="trf_Country"
                      bindLabel="countryName"
                      bindValue="countryId"
                      required
                      [disabled]="viewDisable ? 'true' : 'false'"
                      [(ngModel)]="talentRequisition.country"
                      name="country"
                      #country="ngModel"
                      placeholder="Select country"
                      (change)="getCampus($event.countryId)"
                    >
                    </ng-select>
                    <!-- (change)="trfCountry($event)" -->
                    <div
                      *ngIf="
                        (country.touched || basicInfoo.submitted) &&
                        country.errors?.required
                      "
                    >
                      <span class="errorMessage"> Please select country </span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-6 figma__ng__select">
                    <label for="icon" class="figma__label my-3"
                      >Campus<span class="mandiatory">*</span></label
                    >
                    <ng-select
                      class=""
                      [items]="trfCampus"
                      bindLabel="name"
                      bindValue="campusId"
                      [(ngModel)]="talentRequisition.campus"
                      name="campus"
                      #campus="ngModel"
                      required
                      placeholder="Select campus"
                      [multiple]="true"
                      (click)="campusDetails($event)"
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                      <!-- (change)="campusDetails($event)" -->
                    </ng-select>
                    <div
                      *ngIf="
                        (campus.touched || basicInfoo.submitted) &&
                        campus.errors?.required
                      "
                    >
                      <span class="errorMessage"> Please select campus </span>
                    </div>
                  </div>

                  <div class="col-lg-8 col-6 figma__ng__select">
                    <label for="icon" class="figma__label my-3"
                      >Job Title<span class="mandiatory">*</span></label
                    >
                    <ng-select
                      class="width500"
                      [items]="hierarchy_job"
                      bindLabel="jobTitleName"
                      bindValue="jahId"
                      required
                      [(ngModel)]="talentRequisition.jahJobTitleId"
                      name="jobTitle"
                      #jobTitle="ngModel"
                      [disabled]="viewDisable ? 'true' : 'false'"
                      placeholder="Select job title"
                      [multiple]="false"
                      (change)="validateJobTitle($event)"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (jobTitle.touched || basicInfoo.submitted) &&
                        jobTitle.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please select job title
                      </span>
                    </div>
                    <span class="errorMessage">{{ errorjob }}</span>
                  </div>

                  <div class="col-lg-4 col-6 figma__ng__select mt-1">
                    <label for="icon" class="figma__label my-3"
                      >Priority
                    </label>
                    <div class="col-md-12 d-flex pl-0">
                      <p class="d-flex align-items-center">
                        <input
                          class="pointer input__Accent"
                          type="radio"
                          id="declare1"
                          name="declareOne"
                          value="Highpriority"
                          [(ngModel)]="talentRequisition.priority"
                          [disabled]="viewDisable ? 'true' : 'false'"
                          #declareOne="ngModel"
                        />
                        <label
                          for="declare1"
                          class="figma__label pl-2 pt-1"
                          style="width: max-content !important"
                          >High Priority</label
                        >
                      </p>
                      <p class="ml-4 d-flex align-items-center">
                        <input
                          class="pointer input__Accent"
                          type="radio"
                          id="declare2"
                          name="declareOne"
                          value="Normalpriority"
                          [(ngModel)]="talentRequisition.priority"
                          [disabled]="viewDisable ? 'true' : 'false'"
                          #declareOne="ngModel"
                        />
                        <label
                          for="declare2"
                          class="figma__label pl-2 pt-1"
                          style="width: max-content !important"
                          >Normal Priority</label
                        >
                      </p>
                    </div>
                    <div
                      *ngIf="
                        (declareOne.touched || basicInfoo.submitted) &&
                        declareOne.errors?.required
                      "
                    >
                      <span class="errorMessage"> Please select priority </span>
                    </div>
                  </div>

                  <div class="col-lg-4 col-6 figma__ng__select">
                    <label for="icon" class="figma__label my-3">
                      Type of Appointment<span class="mandiatory">*</span>
                    </label>
                    <ng-select
                      class=""
                      (change)="typeOfappointment($event)"
                      [items]="Appointment_data"
                      bindLabel="appointment_Name"
                      bindValue="appointment_Id"
                      placeholder="Select type of appointment"
                      [(ngModel)]="talentRequisition.typesOfAppointment"
                      name="typeOfAppointmenttt"
                      #typeOfAppointmenttt="ngModel"
                      required
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (typeOfAppointmenttt.touched || basicInfoo.submitted) &&
                        typeOfAppointmenttt.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please select type of appointment
                      </span>
                    </div>
                  </div>

                  <div class="col-md-6 col-xl-6"></div>
                  <!-- <div class="col-md-6 col-xl-4"></div> -->

                  <div class="col-md-12" *ngIf="disableemployee">
                    <label for="icon" class="figma__label my-3"
                      >Add Employee Details
                      <span class="mandiatory">*</span></label
                    >
                    <textarea
                      class="figma__textArea w-100"
                      [(ngModel)]="talentRequisition.employeeDetails"
                      required
                      name="employeeDetails"
                      #employeeDetails="ngModel"
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </textarea>
                    <div
                      *ngIf="
                        (employeeDetails.touched || basicInfoo.submitted) &&
                        employeeDetails.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please enter employee details
                      </span>
                    </div>
                  </div>

                  <div class="col-lg-4 col-6 figma__ng__select">
                    <label for="icon" class="figma__label my-3"
                      >Category <span class="mandiatory">*</span></label
                    >
                    <ng-select
                      class=""
                      [items]="categories"
                      bindLabel="jobCategoryName"
                      bindValue="jobCategoryId"
                      required
                      [(ngModel)]="talentRequisition.category"
                      name="Caategory"
                      #Caategory="ngModel"
                      [disabled]="viewDisable ? 'true' : 'false'"
                      placeholder="Select category"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (Caategory.touched || basicInfoo.submitted) &&
                        Caategory.errors?.required
                      "
                    >
                      <span class="errorMessage"> Please select category </span>
                    </div>
                  </div>

                  <div class="col-lg-4 col-6">
                    <label for="icon" class="figma__label my-3"
                      >Number of Vacancies<span class="mandiatory"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      class="figma__Inputs col-md-12 px-0 trf_form_input"
                      placeholder="Enter number of positions"
                      [(ngModel)]="talentRequisition.numberOfPositions"
                      name="numberOfPositions"
                      #numberOfPositions="ngModel"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      required
                      maxlength="2"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                      [disabled]="viewDisable ? 'true' : 'false'"
                    />
                    <div
                      *ngIf="
                        (numberOfPositions.touched || basicInfoo.submitted) &&
                        numberOfPositions.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please enter number of vacancies
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-6 figma__ng__select">
                    <label for="icon" class="figma__label my-3"
                      >Budgeted / Non-budgeted<span class="mandiatory"
                        >*</span
                      ></label
                    >
                    <ng-select
                      class=""
                      [items]="hyring_Type"
                      bindLabel="hiring_Name"
                      bindValue="hiring_Id"
                      placeholder="Select budgeted / non-budgeted"
                      [multiple]="false"
                      [(ngModel)]="talentRequisition.budOrNonBud"
                      name="budOrNonBud"
                      #budOrNonBud="ngModel"
                      required
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (budOrNonBud.touched || basicInfoo.submitted) &&
                        budOrNonBud.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please select budgeted / non-budgeted
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-6 figma__ng__select">
                    <label for="icon" class="figma__label my-3">
                      Type of Employment<span class="mandiatory">*</span>
                    </label>
                    <ng-select
                      class=""
                      [items]="employement_details"
                      bindLabel="employment_Name"
                      bindValue="employment_Id"
                      placeholder="Select type of employment"
                      [(ngModel)]="talentRequisition.typeOfEmployment"
                      name="typeOfEmployment"
                      #typeOfEmployment="ngModel"
                      required
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (typeOfEmployment.touched || basicInfoo.submitted) &&
                        typeOfEmployment.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please select type of employment
                      </span>
                    </div>
                  </div>
                  <div
                    class="col-lg-4 col-6 figma__ng__select new-trf-select jah_tooltip"
                  >
                    <label for="icon" class="figma__label my-3 d-flex"
                      >Offered Salary Range (Annually)<span class="mandiatory"
                        >*</span
                      >
                      <!-- tooltip -->
                      <div class="tooltip ttoooltipp ml-1">
                        <svg
                          id="eligibilityIIcon"
                          width="14"
                          height="14 "
                          fill="currentColor"
                          class="bi bi-info-circle absolute pointer eligibility-i-icon"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          />
                          <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                          />
                        </svg>
                        <span
                          class="tooltiptext addJobs_tooltiptext eligibility_tooltip_span px-2"
                          id="trfTooltiptext"
                        >
                          If you select Singapore Position, then you have to
                          select INR or USD only.
                        </span>
                      </div>
                      <!-- tooltip -->
                    </label>
                    <div class="d-flex align-items-center">
                      <!-- <input
                      type="text"
                      class="figma__Inputs px-0 trf_form_input input__curr"
                      placeholder="INR"
                      style="border: 1px solid #764fdb !important"
                      name="ctcCode"
                      #ctcCode="ngModel"
                      [(ngModel)]="talentRequisition.ctcCode"
                      readonly
                    /> -->
                      <ng-select
                        class="pe-0"
                        [items]="newCurrenyCodeArray"
                        #expectedSALary="ngModel"
                        name="ctcCode"
                        placeholder=""
                        bindLabel="currencyCode"
                        bindValue="currencyCode"
                        [(ngModel)]="talentRequisition.ctcCode"
                        (change)="clearSalaryinputFields()"
                        required
                      >
                      </ng-select>
                      <input
                        style="width: 100% !important"
                        type="text"
                        class="figma__Inputs px-0 trf_form_input ml-1"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        [(ngModel)]="talentRequisition.offerSalaryRangeFrom"
                        name="offerSalaryRangeFrom"
                        #offerSalaryRangeFrom="ngModel"
                        required
                        (keyup)="minSalaryCountryValidation()"
                        [disabled]="viewDisable ? 'true' : 'false'"
                        appDigitDecimal
                      />
                      <span class="figma__label mx-1"> To </span>
                      <input
                        style="
                          width: 100% !important;
                          border-radius: 8px !important;
                        "
                        type="text"
                        class="figma__Inputs px-0 trf_form_input"
                        (keyup)="validSalaryrange($event) || getValueTwo()"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        [(ngModel)]="talentRequisition.offerSalaryRangeTo"
                        name="offerSalaryRangeTo"
                        #offerSalaryRangeTo="ngModel"
                        required
                        [disabled]="viewDisable ? 'true' : 'false'"
                        appDigitDecimal
                      />
                    </div>
                    <div *ngIf="newSalaryValidation">
                      <span class="errorMessage">
                        Please enter 50000 above
                      </span>
                    </div>
                    <div *ngIf="newSalaryValidationTwo">
                      <span class="errorMessage">
                        Please enter 5000 above
                      </span>
                    </div>
                    <!-- <span class="errorMessage" *ngIf="showErrorExpectSalary"
                  ng-show="basicInfoo.expectedSalaryFrom.$error.min">Value should be greater than
                  {{ basicInfooData.expectedSalaryFrom }}.</span> -->
                    <div
                      *ngIf="
                        (offerSalaryRangeFrom.touched ||
                          basicInfoo.submitted) &&
                        offerSalaryRangeFrom.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please enter offered salary range
                      </span>
                    </div>
                    <span class="errorMessage" *ngIf="trfSalaryRangeError">
                      Please enter valid salary range
                    </span>
                    <!-- <div *ngIf="ctc.errors">
                   pattern="^[^.]*$"
                  <span class="errorMessage" *ngIf="ctc.errors?.pattern">
                    Invalid ctc
                  </span>
                </div> -->
                  </div>
                  <div
                    class="col-lg-4 col-6 field mobile_fields_gap figma_calender_input"
                  >
                    <label class="form-label-d new__input__label mt-3"
                      >Expected Joining Date<span class="mandiatory"
                        >*</span
                      ></label
                    >
                    <p-calendar
                      class="position-relative"
                      name="expectedJoiningDate"
                      placeholder="Select date"
                      [style]="{ width: '100%' }"
                      readonlyInput="readonlyInput"
                      [minDate]="minStartDate"
                      [inputStyle]="{ width: '100%' }"
                      [disabled]="viewDisable ? 'disabled' : null"
                      [(ngModel)]="talentRequisition.expectedJoiningDate"
                      dateFormat="dd/mm/yy"
                      #expectedJoiningDate="ngModel"
                      inputId="icon"
                      [required]="true"
                      [showIcon]="true"
                    ></p-calendar>
                    <!-- <img class="position-absolute calender__icon"
                  src="{{appSettings.imageUrl}}/img/calendar-figma-icon.svg" alt="calender" /> -->
                    <div
                      *ngIf="
                        (expectedJoiningDate.touched || basicInfoo.submitted) &&
                        expectedJoiningDate.errors?.required
                      "
                    >
                      <span class="errorMessage"> Please select date </span>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-6 mt-1 mobile_fields_gap figma__ng__select mt-2"
                  >
                    <label class="form-label-d mt-1 new__input__label mb-1"
                      >Total Experience(in years)
                      <span class="mandiatory">*</span>
                    </label>
                    <div class="d-flex">
                      <div class="col-md-6 px-0">
                        <ng-select
                          class="custom"
                          [items]="fromyears"
                          bindLabel="name"
                          bindValue="name"
                          placeholder=""
                          name="id"
                          (change)="totoalExperienc($event, talentRequisition)"
                          [disabled]="viewDisable ? 'true' : 'false'"
                          [(ngModel)]="talentRequisition.totalExperienceFrom"
                          #totalExperienceFrom="ngModel"
                          name="totalExperienceFrom"
                          style="width: 96% !important"
                          required
                        >
                        </ng-select>
                        <div
                          *ngIf="
                            (totalExperienceFrom.touched ||
                              basicInfoo.submitted) &&
                            totalExperienceFrom.errors?.required
                          "
                        >
                          <span class="errorMessage">
                            Please select experience
                          </span>
                        </div>
                      </div>
                      <span class="form-label-d new__from__to mx-1 mt-2">
                        To
                      </span>
                      <div class="col-md-6 pl-0">
                        <ng-select
                          class="custom"
                          [items]="toyears"
                          bindLabel="name"
                          bindValue="name"
                          placeholder=""
                          name="id"
                          [(ngModel)]="talentRequisition.totalExperienceTo"
                          name="totalExperienceTo"
                          #totalExperienceTo="ngModel"
                          style="width: 96% !important"
                          required
                          [disabled]="viewDisable ? 'true' : 'false'"
                        >
                        </ng-select>
                        <div
                          class="width-max"
                          *ngIf="
                            (totalExperienceTo.touched ||
                              basicInfoo.submitted) &&
                            totalExperienceTo.errors?.required
                          "
                        >
                          <span class="errorMessage">
                            Please select experience
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="d-flex">
                  <div *ngIf="(totalExperienceFrom.touched || basicInfoo.submitted) && totalExperienceFrom.errors?.required">
                    <span class="errorMessage">
                      Please select experience
                    </span>
                  </div>

                  <div
                  *ngIf="(totalExperienceTo.touched || basicInfoo.submitted) && totalExperienceTo.errors?.required">
                  <span class="errorMessage" style="margin-left: 38px">
                    Please select experience
                  </span>
                </div>
                </div> -->
                  </div>

                  <!-- Higher level of education -->
                  <div
                    class="col-lg-4 col-6 field three__parts__margin mobile_fields_gap figma__ng__select mt-3"
                  >
                    <label class="form-label-d new__input__label"
                      >Higher Level of Education</label
                    >

                    <ng-select
                      [items]="Education_details"
                      #higherLevelOfEducation="ngModel"
                      name="higherLevelOfEducation"
                      placeholder="Select education"
                      bindLabel="educationName"
                      bindValue="educationId"
                      [(ngModel)]="talentRequisition.higherLevelOfEducation"
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (higherLevelOfEducation.touched ||
                          basicInfoo.submitted) &&
                        higherLevelOfEducation.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please select higher level education
                      </span>
                    </div>
                  </div>
                  <!-- Higher level of education -->

                  <!-- qualifications -->
                  <div
                    class="col-lg-4 col-6 field qualifications__part three__parts__margin mobile_fields_gap figma__ng__select mt-3"
                  >
                    <label class="form-label-d new__input__label"
                      >Qualifications</label
                    >

                    <ng-select
                      [items]="degree"
                      #qualification="ngModel"
                      name="qualification"
                      placeholder="Select qualifications"
                      bindLabel="degreeName"
                      bindValue="degreeId"
                      [multiple]="true"
                      [(ngModel)]="talentRequisition.qualification"
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (qualification.touched || basicInfoo.submitted) &&
                        qualification.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please select qualifiCations
                      </span>
                    </div>
                  </div>
                  <!-- qualifications -->

                  <!-- <div class="col-md-4">
                <label for="icon" class="figma__label my-3">Total Incurred Cost<span class="mandiatory">*</span></label>
                <div class="d-flex">
                  <input type="text" class="figma__Inputs px-0 trf_form_input input__curr" placeholder="INR"
                    style="border: 1px solid #764fdb !important;" name="totalIncurredCostCode"
                    #totalIncurredCostCode="ngModel" [(ngModel)]="talentRequisition.totalIncurredCostCode" readonly />
                  <input style="width:100% !important; border-radius: 0 8px 8px 0 !important;" type="text"
                    class="figma__Inputs px-0 trf_form_input" placeholder="Total incurred cost"
                    onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'
                    [(ngModel)]="talentRequisition.totalIncurredCost" name="totalIncurredCost"
                    #totalIncurredCost="ngModel" required [disabled]="viewDisable?'true':'false'" appDigitDecimal/>
                </div>
                <div *ngIf="(totalIncurredCost.touched || basicInfoo.submitted) &&totalIncurredCost.errors?.required">
                  <span class="errorMessage">
                    Please enter total incurred cost
                  </span>
                </div>
              </div> -->
                  <!-- <div class="col-md-4 figma__ng__select"> -->
                  <!-- <label for="icon" class="figma__label my-3">Approved By <span class="mandiatory">*</span></label>
                <ng-select class="" [items]="trfApprovedBy" bindLabel="name" bindValue="id" required
                  [(ngModel)]="talentRequisition.approvedBy" name="approvedBy" #approvedBy="ngModel"
                  placeholder="Select approved by">
                </ng-select>
                <div *ngIf="(approvedBy.touched || basicInfoo.submitted) &&approvedBy.errors?.required">
                  <span class="errorMessage">
                    Please select approved by
                  </span>
                </div>
              </div> -->
                </div>
              </div>

              <div class="col-md-12 px-0 mt-4">
                <button
                  type="button"
                  class="blue-btn"
                  style="float: right"
                  (click)="trfNextScreen()"
                  *ngIf="trfNormalButtons"
                >
                  <!-- && jobRes===false -->
                  Job Description
                </button>
                <!-- [disabled]="errorJobTitleDisabled || trfSalaryRangeError || newSalaryValidation || newSalaryValidationTwo" -->
                <button
                  type="button"
                  class="secondary-btn"
                  style="float: right"
                  (click)="trfNextScreen()"
                  *ngIf="trfViewbuttons"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
          <form novalidate #descriptionForm="ngForm">
            <div *ngIf="rafJobDescription">
              <div
                class="raf__Two_descriptionn_main background__wrapper row"
                *ngIf="!trfTwoSubmitDisabled"
              >
                <div class="col-md-12" *ngIf="trfNormalButtons">
                  <label for="icon" class="figma__label my-3"
                    >Overview <span class="mandiatory">*</span></label
                  >
                  <p-editor
                    [editor]="Editor"
                    name="classic-editor"
                    [(ngModel)]="trfJobDescription.overview"
                    name="overview"
                    #overview="ngModel"
                    required
                  >
                  </p-editor>
                  <div
                    *ngIf="
                      (overview.touched || descriptionForm.submitted) &&
                      overview.errors?.required
                    "
                  >
                    <span class="errorMessage"> Please add content </span>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="trfViewbuttons">
                  <label for="icon" class="figma__label my-3"
                    >Overview <span class="mandiatory">*</span></label
                  >

                  <p
                    class="view-view"
                    [innerHTML]="trfJobDescription.overview"
                  ></p>

                  <!-- <textarea class="col-md-12 px-0 disabled__trf__textareas" [(ngModel)]="trfJobDescription.overview"
                name="overview" #overview="ngModel" [disabled]="viewDisable?'true':'false'">
              </textarea>
              <div *ngIf="(overview.touched || descriptionForm.submitted) &&overview.errors?.required">
                <span class="errorMessage">
                  Please add content
                </span>
              </div> -->
                </div>
                <div class="col-md-12" *ngIf="trfNormalButtons">
                  <label for="icon" class="figma__label my-3"
                    >Responsibilities<span class="mandiatory">*</span>
                  </label>
                  <p-editor
                    [editor]="Editor"
                    name="classic-editor"
                    [(ngModel)]="trfJobDescription.responsibilities"
                    name="responsibilities"
                    #responsibilities="ngModel"
                    required
                  >
                  </p-editor>
                  <div
                    *ngIf="
                      (responsibilities.touched || descriptionForm.submitted) &&
                      responsibilities.errors?.required
                    "
                  >
                    <span class="errorMessage"> Please add content </span>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="trfViewbuttons">
                  <label for="icon" class="figma__label my-3"
                    >Responsibilities<span class="mandiatory">*</span>
                  </label>

                  <p
                    class="view-view"
                    [innerHTML]="trfJobDescription.responsibilities"
                  ></p>

                  <!-- <textarea class="col-md-12 px-0 disabled__trf__textareas" [(ngModel)]="trfJobDescription.responsibilities"
                name="responsibilities" #responsibilities="ngModel" [disabled]="viewDisable?'true':'false'">
              </textarea>
              <div *ngIf="(responsibilities.touched || descriptionForm.submitted) &&responsibilities.errors?.required">
                <span class="errorMessage">
                  Please add content
                </span>
              </div> -->
                </div>
                <div class="col-md-12" *ngIf="trfNormalButtons">
                  <label for="icon" class="figma__label my-3"
                    >Skills Description<span class="mandiatory">*</span>
                  </label>
                  <p-editor
                    name="classic-editor"
                    [(ngModel)]="trfJobDescription.skillsDescription"
                    name="skillsDescription"
                    #skillsDescription="ngModel"
                    required
                  >
                  </p-editor>
                  <div
                    *ngIf="
                      (skillsDescription.touched ||
                        descriptionForm.submitted) &&
                      skillsDescription.errors?.required
                    "
                  >
                    <span class="errorMessage"> Please add content </span>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="trfViewbuttons">
                  <label for="icon" class="figma__label my-3"
                    >Skills Description<span class="mandiatory">*</span>
                  </label>

                  <p
                    class="view-view"
                    [innerHTML]="trfJobDescription.skillsDescription"
                  ></p>

                  <!-- <p class="col-md-12 px-0 disabled__trf__textareas" [(ngModel)]="trfJobDescription.skillsDescription"
                name="skillsDescription" #skillsDescription="ngModel" [disabled]="viewDisable?'true':'false'">
              </p> -->
                  <!-- <div *ngIf="(skillsDescription.touched || descriptionForm.submitted) &&skillsDescription.errors?.required">
                <span class="errorMessage">
                  Please add content
                </span>
              </div> -->
                </div>

                <!-- <div class="col-md-12" *ngIf="trfNormalButtons">
              <label for="icon" class="figma__label my-3">Website Short Description<span class="mandiatory">*</span>
              </label>
              <ckeditor [editor]="Editor" name="classic-editor" required
                [(ngModel)]="trfJobDescription.websiteShortDescription" name="websiteShortDescription"
                #websiteShortDescription="ngModel">
              </ckeditor>
              <div
                *ngIf="(websiteShortDescription.touched || descriptionForm.submitted) &&websiteShortDescription.errors?.required">
                <span class="errorMessage">
                  Please add content
                </span>
              </div>
            </div> -->

                <!-- <div class="col-md-12" *ngIf="trfViewbuttons">
              <label for="icon" class="figma__label my-3">Website Short Description<span class="mandiatory">*</span>
              </label>
              <textarea class="col-md-12 px-0 disabled__trf__textareas"
                [(ngModel)]="trfJobDescription.websiteShortDescription" name="websiteShortDescription"
                #websiteShortDescription="ngModel" [disabled]="viewDisable?'true':'false'">
              </textarea>
              <div
                *ngIf="(websiteShortDescription.touched || descriptionForm.submitted) &&websiteShortDescription.errors?.required">
                <span class="errorMessage">
                  Please add content
                </span>
              </div>
            </div> -->

                <!-- <div class="col-md-12" *ngIf="trfNormalButtons">
              <label for="icon" class="figma__label my-3">Disclaimer<span class="mandiatory">*</span>
              </label>
              <ckeditor [editor]="Editor" name="classic-editor" required [(ngModel)]="trfJobDescription.disclaimer"
                name="websiteDisclaimer" #websiteDisclaimer="ngModel">
              </ckeditor>
              <div *ngIf="(websiteDisclaimer.touched || descriptionForm.submitted) &&websiteDisclaimer.errors?.required">
                <span class="errorMessage">
                  Please add content
                </span>
              </div>
            </div> -->

                <!-- <div class="col-md-12" *ngIf="trfViewbuttons">
              <label for="icon" class="figma__label my-3">Disclaimer<span class="mandiatory">*</span>
              </label>
              <textarea class="col-md-12 px-0 disabled__trf__textareas" [(ngModel)]="trfJobDescription.disclaimer"
                name="websiteDisclaimer" #websiteDisclaimer="ngModel" [disabled]="viewDisable?'true':'false'">
              </textarea>
              <div *ngIf="(websiteDisclaimer.touched || descriptionForm.submitted) &&websiteDisclaimer.errors?.required">
                <span class="errorMessage">
                  Please add content
                </span>
              </div>
            </div> -->

                <div
                  class="d-md-flex col-md-12 figma__ng__select mt-3"
                  *ngFor="let item of TRFInfoData.jobskill; let i = index"
                >
                  <div
                    class="col-md-4 field form-group-d pl-0 pr-4 mobile_skills_category mobile_width_eighty"
                  >
                    <label class="form-label-d new__input__label"
                      >Skills Category<span class="mandiatory">*</span></label
                    >

                    <ng-select
                      [items]="skill_list"
                      bindLabel="skill_CategoryName"
                      bindValue="skill_CategoryId"
                      required
                      [(ngModel)]="item.skillCategory"
                      name="trfSkillsCCAtegorry{{ i }}"
                      (change)="SkillNameById($event, i)"
                      #trfSkillsCCAtegorry="ngModel"
                      placeholder="Select skills category"
                      [multiple]="false"
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (trfSkillsCCAtegorry.touched ||
                          descriptionForm.submitted) &&
                        trfSkillsCCAtegorry.errors?.required
                      "
                    >
                      <span class="errorMessage">
                        Please select skills category
                      </span>
                    </div>
                  </div>
                  <div
                    class="col-md-4 field form-group-d mobile__Skills mobile_width_eighty"
                  >
                    <label class="form-label-d new__input__label"
                      >Skills<span class="mandiatory">*</span></label
                    >

                    <ng-select
                      [items]="item.skill_list"
                      bindLabel="skillName"
                      bindValue="skillCategoryId"
                      [(ngModel)]="item.skills"
                      name="item.trffSkiillss{{ i }}"
                      #trffSkiillss="ngModel"
                      placeholder="Select skills"
                      [multiple]="true"
                      required
                      [disabled]="viewDisable ? 'true' : 'false'"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        (trffSkiillss.touched || descriptionForm.submitted) &&
                        trffSkiillss.errors?.required
                      "
                    >
                      <span class="errorMessage"> Please select skills </span>
                    </div>
                  </div>

                  <div
                    class="document_plus_btn ml-5"
                    style="margin-top: 31px !important"
                  >
                    <!-- <button type="button" class="plus-icon-button mr-1"
                  *ngIf="((i == TRFInfoData.jobskill.length - 1) || (TRFInfoData.jobskill.length == 1 )) && (skill_list.length > 0)" (click)="addSkill()">
                  <img src="{{appSettings.imageUrl}}/img/new-new-plus-icon.png" alt="pplus"
                    style="height: 15px; margin-top: -4px;">
                </button>
                <button type="button" class="new_figma_delete_red_btn btn" (click)="deleteSkill(i)"
                  *ngIf="(TRFInfoData.jobskill.length === ( i+1)) &&  (i !=0 )">
                  <img src="{{appSettings.imageUrl}}/img/white-delete-icon.png" alt="" />
                </button> -->
                    <span
                      role="button"
                      class="add__Text"
                      *ngIf="
                        (i == TRFInfoData.jobskill.length - 1 ||
                          TRFInfoData.jobskill.length == 1) &&
                        !trfViewbuttons
                      "
                      (click)="addSkill()"
                    >
                      Add More Skills
                    </span>
                    <span
                      role="button"
                      class="add__Text remove__Text"
                      style="color: rgba(254, 84, 84, 1) !important"
                      (click)="deleteSkill(i)"
                      *ngIf="
                        TRFInfoData.jobskill.length === i + 1 &&
                        i != 0 &&
                        !trfViewbuttons
                      "
                    >
                      Remove
                    </span>
                  </div>
                </div>
                <div class="col-md-12 mt-3 justify-content-between d-flex">
                  <button
                    type="button"
                    class="secondary-btn"
                    style="float: left"
                    (click)="respectiveScreen('basicinfo')"
                    *ngIf="trfNormalButtons"
                  >
                    Basic Info
                  </button>
                  <button
                    type="button"
                    class="secondary-btn"
                    style="float: left"
                    (click)="respectiveScreen('basicinfo')"
                    *ngIf="trfViewbuttons"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    class="blue-btn"
                    style="padding: 8px 30px !important"
                    (click)="createTrf()"
                    *ngIf="!trfViewbuttons"
                    [disabled]="isSubmitting"
                  >
                    Submit
                  </button>
                  <!-- [disabled]="trfTwoSubmitDisabled || descriptionForm.invalid" -->
                </div>

                <!-- <div class="col-md-4 figma__ng__select">
            <label for="icon" class="figma__label my-3">Skills Category</label>
            <ng-select class="" [items]="trfSkillsCategory" bindLabel="name" bindValue="id"
              [(ngModel)]="skillCategory" name="trfSkillsCCAtegorry" placeholder="Select skills category"
              [multiple]="false">
            </ng-select>
          </div>

          <div class="col-md-4 figma__ng__select">
            <label for="icon" class="figma__label my-3">Skills</label>
            <ng-select class="" [items]="trfSkillss" bindLabel="name" bindValue="id" [(ngModel)]="skills"
              name="trffSkiillss" placeholder="Select skills" [multiple]="true">
            </ng-select>
          </div> -->
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- jobtitle popup -->
      <div
        *ngIf="trfJobTitleMOdal"
        id="addApplicantModalMain"
        class="addApplicant__modal_main trfJobTitleMOdal_mainn back_drop"
      >
        <div
          class="addApplicant__mod__content"
          style="height: auto !important; top: 100px"
        >
          <div class="modal__heading d-flex align-items-baseline">
            <h5
              class="add__app__heading"
              id="addApplicantTitle"
              style="margin-bottom: 30px !important"
            >
              Alert<span class="mandiatory">*</span>
            </h5>
            <!-- <button type="button" class="close new_figma_blue_btn figma__close relative ml-auto"
              (click)="CloseTrfJobTitlepopUP()">
              <img class="absolute" src="{{ appSettings.imageUrl }}/img/blue-close-icon.svg" alt="close" />
            </button> -->
          </div>
          <div>
            Cannot add <span class="fw600">{{ titleError }} </span> for
            <span class="fw600">{{ popupError }} </span> Campus. Please contact
            HR team and provide ERROR CODE-ER401”
            <!-- <span class="fw600">{{titleError}} </span> for <span class="fw600">{{popupError}} </span>Campus doesn't have the Approval Hierarchy set . Please contact HR team to add the same.and provide ERROR CODE-ERJAH401 -->
            <!-- <p>
          <span class="fw600">{{popupError}} </span> : For  <span class="fw600">
            <div *ngFor="let x of commaSeperated(popupError)"> {{x}} </div>
          </span> campus doesn't have the Approval Hierarchy set . Please contact HR team to add the same.
        </p> -->
          </div>
          <!-- <span class="select_option_span"> Please choose the below option: </span> -->

          <button
            type="button"
            class="p12-20 figma_background_blue_btn d-flex mx-auto mt-3"
            (click)="CloseTrfJobTitlepopUP()"
          >
            Okay
          </button>
        </div>
      </div>
      <!-- jobtitle popup -->
    </div>
  </ng-container>
</section>

<div
  class="reject_Reason_main recruitment__memm_mainn absolute z-999"
  *ngIf="myModal"
>
  <div
    class="reject_Reasonnn recruit__memberrs relative jobs_recruit_popup"
    style="height: fit-content !important"
  >
    <div class="d-flex align-items-center justify-content-baseline mb-3">
      <p class="figma_blue_color fw600 fs14">
        {{ approverData[0].approverDesignation }}
      </p>
      <button class="new_figma_blue_btn ml-auto" (click)="closepopup()">
        <!-- <img src="{{ appSettings.imageUrl }}/img/figma-close-iconBlue-1.svg" alt="cross" /> -->
        <img src="./assets/img/figma-close-iconBlue-1.svg" alt="cross" />
      </button>
    </div>
    <table class="aj3 col-md-12">
      <thead style="background: #764fdb">
        <tr>
          <th
            class="pr30 t-w fs14 fw600"
            style="padding: 8px; width: 125px; min-width: 125px"
          >
            Approval Level
          </th>
          <th class="t-w fs14 fw600" style="padding: 8px">Approver Member</th>
        </tr>
      </thead>
      <tr *ngFor="let x of approverData; let i = index">
        <td class="fs14 fw500 font-black-3" style="padding: 10px">
          {{ x.approverStage }}
        </td>
        <!-- <td style="padding: 10px">
          <ng-container *ngFor="let xy of commaSeperated(x.jobTitle)"> {{xy}} </ng-container>
        </td> -->
        <td class="" style="padding: 10px">
          <div
            class="fs12 fw500 font-black-3"
            *ngFor="let xy of commaSeperated(x.approverName)"
          >
            {{ xy }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
