<!-- <div class="dashboard-cart-card">
    <h5>dashboard</h5>
    <p-chart type="bar" [data]="stackedData" [options]="stackedOptions" class="dashboard-chart"></p-chart>
</div> -->
<!-- <div class="custom-loader" *ngIf="loading">
  <img src="./assets/Images/gsf-new-logo 2.svg" alt="Loader" />
</div> -->
<div class="loader" *ngIf="dashboardLoading"></div>


<!-- prudhvi -->
<!-- <div style="display: block;">
    <canvas
      baseChart
      [datasets]="pbarChartData"
      [labels]="pbarChartLabels"
      [options]="pbarChartOptions"
      [plugins]="pbarChartPlugins"
      [legend]="pbarChartLegend"
      [type]="pbarChartType"
    >
    </canvas>
  </div> -->

<!-- prudhvi -->


<!-- *ngIf="!loading" -->
<!-- <div class='loaderrrr' *ngIf="dashboardLoading">
  <div class='loader--text'></div>
</div> -->

<main class="dashboard-main pr-2" *ngIf="!dashboardLoading">
  <div class="d-flex align-items-center justify-content-between my-4">
  <h1 class="fs26 fw600">Dashboard</h1>
    <!-- <div class=" d-flex align-items-center">
      <img src="./assets/Images/calender-icon.svg" alt="" />
      <span class="fs14 fw600 primary_txt ml-2">{{ today | date: 'EEEE, dd MMMM' }}</span>
    </div> -->

    <div class="">
      <span class="fs22 fw600 primary_txt">Good Morning, {{userDetails.UserFullName}} 👋 </span>
    </div>
    <div class="tabs-container">
      <div *ngIf="roleExists || !roleExists"
        class="tab"
        (click)="goToHomePage()"
        [class.active]="activeRoute === '/employee-dashboard'">
        Home Page
      </div>
    <div *ngIf="!roleExists"
    class="tab"
    [routerLink]="'/dashboard-reports'"
    [class.active]="activeRoute === '/dashboard-reports' || activeRoute === '/'">
    HR Dashboard
    </div>

    </div>
  </div>
  <div class="dashboard_tabs">

  <!-- <p-tabView [(activeIndex)]="activeTabIndexDetailsViewUpdate" *ngIf="!roleExists" (onChange)="onTabChangeTab($event)">
    <p-tabPanel *ngFor="let tab of tabsDetails; let i = index" >
      <ng-template pTemplate="header">
        {{ tab.title }}
        <span class="tabs_count">{{ tab.count }}</span>
      </ng-template> -->

  <section class="dashboard-top-part">
    <div class="white-background p16 br8 mb-3 d-flex align-items-center gap-3">
      <div class="dashboard-linee d-flex align-items-center">
        <h1 class="fs26 fw600">Dashboard</h1>
        <!-- <svg
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-chevron-down pointer"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
          />
        </svg> -->
      </div>
      <div class="dashboard-patches ml-3">
        <div class="d-flex flex-row flex-wrap single-patche-main">
          <ng-container *ngFor="let patch of patchesRowArray; let i = index">
            <div
              class="single-patche d-flex align-items-center gap-2 "
            >
              <h1 class="fw500 fs14">{{ patch.patchName }}</h1>
              <img
                *ngIf="i != 0"
                class="pointer ml-2"
                (click)="removeSinglePatch(patch.patchName)"
                src="./assets/Images/grey-cross.svg"
                alt="grey cross"
              />
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-filters white-background p16 br8 mb-3">
    <p-accordion [(activeIndex)]="activeAccordionIndex" class="p16">
      <p-accordionTab header="Dashboard Filters">
        <div class="line mx-4 my-2"></div>
        <form [formGroup]="dashboardForm">
          <div class="dashboard-filter-inputs white-background p16">
            <div class="figma-ng-select">
              <label> School </label>
              <ng-select
                (change)="GetBrand('change', $event)"
                placeholder="Select school"
                [items]="brandArray"
                bindLabel="brandName"
                [multiple]="true"
                bindValue="brandId"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="brandFirstObj"
              >
              </ng-select>
            </div>
            <div class="figma-ng-select">
              <label> Country </label>
              <ng-select
                (change)="GetCountry($event)"
                placeholder="Select country"
                [multiple]="true"
                [items]="countryArray"
                bindLabel="countryName"
                bindValue="countryId"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="dashboardCountryFilterItem"
              >
              </ng-select>
            </div>
            <div class="figma-ng-select">
              <label> Campus </label>
              <ng-select
                (change)="GetCampus($event);"
                placeholder="Select campus"
                [multiple]="true"
                [items]="campusArr"
                bindLabel="campusName"
                bindValue="campusId"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="dashboardCampusFilterItem"
              >
              </ng-select>
            </div>
            <div class="figma-ng-select">
              <label> Job Category </label>
              <ng-select
                (change)="GetCategory($event); "
                placeholder="Select job category"
                [multiple]="true"
                [items]="jobCategoryArr"
                bindLabel="categoryName"
                bindValue="jobCategory"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="dashboardJobCategoryFilterItem"
              >
              </ng-select>
            </div>
            <div class="figma-ng-select">
              <label> Department </label>
              <ng-select
                (change)="GetDepartment($event); "
                placeholder="Select department"
                [multiple]="true"
                [items]="departmentArr"
                bindLabel="departmentName"
                bindValue="departmentId"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="dashboardDepartmentFilterItem"
              >
              </ng-select>
            </div>
            <!-- <div class="figma-ng-select">
                        <label> Job Title </label>
                        <ng-select placeholder="Select"
                            [items]=" dashboardJobTitleFilter"
                            bindLabel="name"
                            bindValue="id"
                            [ngModelOptions]="{standalone: true}" [(ngModel)] ="dashboardJobTitleFilterItem"
                        >
                        </ng-select>
                        </div> -->
            <div class="figma-ng-select">
              <label> Source </label>
              <ng-select
                (change)="GetSourceId($event); "
                placeholder="Select source"
                [multiple]="true"
                [items]="sourceArr"
                bindLabel="jopPost_Name"
                bindValue="jobPostId"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="dashboardSourceFilterItem"
              >
              </ng-select>
            </div>
            <div class="dashboard-filter-calendar figma-calender">
              <label class="d-flex align-items-center">Date Range </label>
              <div
                _ngcontent-ng-c533179670=""
                class="d-flex align-items-center gap10"
              >
                <div class="figma-calender position-relative">
                  <p-calendar
                    (onSelect)="
                      setdateInfo(dateRangeFrom);
                    "
                    [(ngModel)]="dateRangeFrom"
                    name="dateRangeFrom"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="From"
                    [showIcon]="true"
                  ></p-calendar>
                </div>
                <div class="figma-calender position-relative">
                  <p-calendar

                    [(ngModel)]="dateRangeTo"
                    [minDate]="selectedstartDate"
                    name="dateRangeTo"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="To"
                    [showIcon]="true"
                  ></p-calendar>
                </div>
              </div>
            </div>

            <div class="figma-ng-select">
              <label> Job Status </label>
              <ng-select

                placeholder="Select job status"
                [multiple]="false"
                [items]="jobStatusArray"
                bindLabel="name"
                bindValue="id"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="dashboardjobStatusArray"
              >
              </ng-select>
            </div>


          </div>
          <div class="dasahboardFilterBtn d-flex justify-content-end p16 br8">
            <button
              type="button"
              class="secondary-btn"
              (click)="resetCriteria()"
            >
              Clear Filter
            </button>
            <button
              type="submit"
              class="ml-3 blue-btn"
              (click)="savefilterCriteria()"
            >
              Save Criteria
            </button>
          </div>
        </form>
      </p-accordionTab>
    </p-accordion>
  </section>

  <section class="dashboard-tracker mt-4">
    <div class="d-flex justify-content-between">
      <h2>Tracker Report</h2>
      <!-- <div class="d-md-flex align-items-center toggle-switch">
        <label for="" class="me-2">Today’s Status</label>
        <input type="checkbox" role="switch" />
        <label for="" class="ms-2"> Overall Status</label>
      </div> -->
    </div>
    <div class="line"></div>
    <div class="tracker-report-cards">
      <div class="tracker-card jobs-position white-background p16 d-flex align-items-center justify-content-between">
        <ng-container *ngIf="!skeletonOne">
        <div class="left--card">
        <p class="fs14 fw600 align-items-start">Jobs </p>
        <p class="fs10 fw500 ">Active / Closed</p>
        <p class="fs16 fw600 d-flex ">
          <span>{{ activeJobs }} / {{ closedJobs }} </span>
          <!-- {{ pendingJobs }} -->
        </p>
        <p class="fs10 fw600">Total Jobs: {{ totalJobs }}</p>
        </div>
        <div class="right-left-line"></div>
        <div class="left--card right--card">
        <p class="fs14 fw600 align-items-start">Vacancies</p>
        <p class="fs10 fw500 ">Active / Closed</p>
        <p class="fs16 fw600 d-flex">
          <span>{{ activeVacancyCount }} / {{ closedVacancyCount }} </span>
        </p>
        <p class="fs10 fw600">Total Vacancies: {{ totalVacancyCount }}</p>
        </div>
        </ng-container>
        <ng-container *ngIf="skeletonOne">
        <div class="my-loading-skeleton w-100 d-flex align-items-center">
          <div class="col-6 pl-0 left-loading">
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
        </div>
          <div class="col-6 pr-0 right-loading">
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton styleClass="mb-2"></p-skeleton>
      </div>
      </div>
    </ng-container>
      </div>

      <div class="tracker-card jobs-position white-background p16 d-flex align-items-center justify-content-between">
        <ng-container *ngIf="!skeletonTwo">
        <div class="left--card">
        <p class="fs14 fw600 align-items-start">Jobs </p>
        <p class="fs10 fw500 ">Academic / Non-Academic</p>
        <p class="fs16 fw600 d-flex">
          <span> {{ academic }} / {{ nonAcademic }} </span>
        </p>
        <p class="fs10 fw600 ">  {{ academicPercentage }}% / {{ nonAcademicPercentage }}% </p>
        </div>
        <div class="right-left-line"></div>
        <div class="left--card right--card">
        <p class="fs14 fw600 align-items-start">Vacancies</p>
        <p class="fs10 fw500 ">Academic / Non-Academic</p>
        <p class="fs16 fw600 d-flex">
          <span> {{ academicVacencyCount }} / {{ nonAcademicVacencyCount }} </span>
        </p>
        <p class="fs10 fw600">  {{ academicVacencyPercentage }} / {{ nonAcademicVacencyPercentage }} </p>
        </div>
         </ng-container>
        <ng-container *ngIf="skeletonTwo">
          <div class="my-loading-skeleton w-100 d-flex align-items-center">
            <div class="col-6 pl-0 left-loading">
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
          </div>
            <div class="col-6 pr-0 right-loading">
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
        </div>
        </div>
      </ng-container>

      </div>

      <!-- <div class="tracker-card academic white-background p16">
        <p class="fs14 fw600">Academic/Non-Academic</p>
        <div class="d-flex align-items-center justify-content-between pt-2">
        <div class="left--card left--card--2">
          <p class="fs10 fw500 dashboard-grey22">Jobs</p>
          <p class="fs16 fw600">
            <span class="pt-2">{{ academic }}/ {{ nonAcademic }}</span>
          </p>
          <p class="fs10 fw600 pt-3">
            {{ academicPercentage }}%/ {{ nonAcademicPercentage }}%
          </p>
        </div>
        <div class="right-left-line"></div>
        <div class="left--card right--card left--card--2">
          <p class="fs10 fw500 dashboard-grey22">Vacancies</p>
          <p class="fs16 fw600">
            <span class="pt-2">{{ academicVacencyCount }}/ {{ nonAcademicVacencyCount }}</span>
          </p>
          <p class="fs10 fw600 pt-3">
            {{ academicVacencyPercentage }} / {{ nonAcademicVacencyPercentage }}
          </p>
        </div>
        </div>
      </div> -->


      <div class="tracker-card jobs-position white-background p16 d-flex align-items-center justify-content-between">
        <ng-container *ngIf="!skeletonThree">
        <div class="left--card">
        <p class="fs14 fw600 align-items-start">Jobs </p>
        <p class="fs10 fw500 ">New / Replacement</p>
        <p class="fs16 fw600 d-flex">
          <span> {{ new }} / {{ replacement }} </span>
        </p>
        <p class="fs10 fw600">   {{ newAverageString }} / {{ replacementAverageString }} </p>
        </div>
        <div class="right-left-line"></div>
        <div class="left--card right--card">
        <p class="fs14 fw600 align-items-start">Vacancies</p>
        <p class="fs10 fw500 ">New / Replacement</p>
        <p class="fs16 fw600 d-flex">
          <span> {{ newVacancyCount }} / {{ replacementVacancyCount }} </span>
        </p>
        <p class="fs10 fw600">    {{ newVacancyPercentage }} / {{ replacementVacancyPercentage }} </p>
        </div>
        </ng-container>

        <ng-container *ngIf="skeletonThree">
          <div class="my-loading-skeleton w-100 d-flex align-items-center">
            <div class="col-6 pl-0 left-loading">
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
          </div>
            <div class="col-6 pr-0 right-loading">
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
        </div>
        </div>
      </ng-container>

      </div>


      <!-- <div class="tracker-card academic replacment white-background p16">
        <p class="fs14 fw600">New Vs Replacement</p>
        <div class="d-flex align-items-center justify-content-between pt-2">
          <div class="left--card left--card--2">
            <p class="fs10 fw500 dashboard-grey22">Jobs </p>
            <p class="fs16 fw600 mt-2 blue-color">{{ new }}/ {{ replacement }}</p>
        <p class="fs10 fw600 dashboard-grey pt-3">
          {{ newAverageString }}/ {{ replacementAverageString }}
        </p>
          </div>
          <div class="right-left-line"></div>
          <div class="left--card right--card left--card--2">
            <p class="fs10 fw500 dashboard-grey22">Vacancies </p>
            <p class="fs16 fw600 mt-2 blue-color">{{ newVacancyCount }}/ {{ replacementVacancyCount }}</p>
        <p class="fs10 fw600 dashboard-grey pt-3">
          {{ newVacancyPercentage }}/ {{ replacementVacancyPercentage }}
        </p>
          </div>
          </div>

      </div> -->
      <div class="tracker-card academic selected-applicants white-background p16">
        <ng-container *ngIf="!skeletonThree">
        <p class="fs14 fw600 align-items-start">Selected Applicants</p>
        <p class="fs16 fw600 blue-color mt-4">{{ totalApplicants }} Applicants</p>
        <p class="fs10 fw600">
          <span class="ml-2 d-flex align-items-center">{{ malePercentage }}% Male</span>
          <span class="ml-3 d-flex align-items-center">{{ femalePercentage }}% Female</span>
        </p>
        </ng-container>

        <ng-container *ngIf="skeletonThree">
          <div class="my-loading-skeleton w-100 d-flex align-items-center">
            <div class="col-12 pl-0 left-loading">
            <p-skeleton styleClass="mb-4"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
          </div>
        </div>
      </ng-container>
      </div>

      <div class="tracker-card academic status-distribution white-background p16">
        <ng-container *ngIf="!skeletonFive">
        <p class="fs14 fw600 align-items-start">Status Distribution</p>
        <p class="fs16 fw600 blue-color mt-4">{{ selectednotselectedapplicants }} Applicants</p>
        <p class="fs12 fw600">
          <span class="ml-2 fs10 d-flex align-items-center">{{ selectedPercentage }}% Selected</span>
          <!-- <span class="ms-3 fs10 d-flex align-items-center">{{ notSelectedPercentage }}% Not Selected</span> -->
        </p>
        </ng-container>

        <ng-container *ngIf="skeletonFive">
          <div class="my-loading-skeleton w-100 d-flex align-items-center">
            <div class="col-12 pl-0 left-loading">
              <p-skeleton styleClass="mb-4"></p-skeleton>
              <p-skeleton styleClass="mb-2"></p-skeleton>
              <p-skeleton styleClass="mb-2"></p-skeleton>
        </div>
        </div>
      </ng-container>
      </div>

      <div class="tracker-card jobs-position white-background p16">
        <ng-container *ngIf="!skeletonSix">
        <p class="fs14 fw600 align-items-start">Job Requisition</p>
        <p class="fs10 fw500">Accepted / Closed / Expired</p>
        <p class="fs16 fw600">
          <span>{{ acceptedAverage }}% / {{ jobClosedAverage }}% /
          {{ expiredAverage }}% </span>
        </p>
        <p class="fs10 fw600">Total Jobs: {{ totaltrf }}</p>
        </ng-container>

        <ng-container *ngIf="skeletonSix">
          <div class="my-loading-skeleton w-100 d-flex align-items-center">
            <div class="col-12 pl-0 left-loading">
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
          </div>

        </div>
      </ng-container>

      </div>
      <!-- <div class="tracker-card jobs-position white-background p16 average-timee--hiree">
        <p class="fs14 fw600 align-items-start">Average Time to Hire &nbsp; <span style="color: orange"> (WIP) </span> </p>
        <p class="fs10 fw500 ">In Weeks</p>
        <p class="fs16 fw600 blue-color">3 Weeks</p>
      </div> -->
      <!-- <div class="tracker-card academic replacment white-background p16">
                <p class="fs14 fw600">New Vs Replacement</p>
                <p class="fs16 fw600 mb-2">136/ 34</p>
                <p class="fs12 fw600">80%/ 20%</p>
            </div> -->
    </div>
  </section>

  <section class="dashboard-stages white-background br8 mt-3 pt-0">
    <!-- <div class="row gx-2"> -->
    <!-- <aside class="col-2 p16">
                <p class="fs16 fw600 black">Stages</p>
                <div class="line"></div>
                <div *ngFor="let category of categories" class="field-checkbox">
                    <p-checkbox [label]="category.name" name="group" [value]="category" [(ngModel)]="selectedCategories" class="grey1 fs14"></p-checkbox>
                </div>

            </aside> -->
    <!-- <div class="dashboard-stages-chart col-10"> -->
    <div class="dashboard-chart ml-2 p12">
      <div class="chart-filter-btn">
        <div class="chart-head">
          <div class="d-flex align-items-center justify-content-between">
            <p class="fs14 fw600 secondary-black">Stages Overview</p>
            <ul class="nav nav-tabs" id="stagesTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  [ngClass]="{ 'active': stagesActiveTab === 'Brand' }"
                  (click)="setActiveTab('Brand')"
                  id="stages-brand-tab"
                  type="button"
                  role="tab"
                  aria-controls="stages-brand"
                  [disabled]="tabsButtonDisabled"
                >
                  School
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  [ngClass]="{ 'active': stagesActiveTab === 'Country' }"
                  (click)="setActiveTab('Country')"
                  id="stages-country-tab"
                  type="button"
                  role="tab"
                  aria-controls="stages-country"
                  [disabled]="tabsButtonDisabled"
                >
                  Country
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  [ngClass]="{ 'active': stagesActiveTab === 'Campus' }"
                  (click)="setActiveTab('Campus')"
                  id="stages-campus-tab"
                  type="button"
                  role="tab"
                  aria-controls="stages-campus"
                  [disabled]="tabsButtonDisabled"
                >
                  Campus
                </button>
              </li>
            </ul>



          </div>
          <div class="line my-2"></div>

        </div>
        <div class="tab-content">
          <div class="tab-pane fade" [ngClass]="{ 'show active': stagesActiveTab === 'Brand' }" id="stages-brand" role="tabpanel" aria-labelledby="stages-brand-tab">
            <div class="small--chart-part align-items-center position-relative">
              <ng-container *ngIf="chartLoading">
                <div class='loaderrrr'>
                  <div class='loader--text'></div>
                </div>
              </ng-container>
              <!-- {{barChartDataobj.data?.length}} -->

              <ng-container *ngIf="!barChartDataobj.data?.length">
                <div class="chart-no-data-found chart-no-data-found--2">
                  <h1> No Data Found </h1>
                </div>
              </ng-container>

              <!-- <ng-container *ngIf="barChartDataobj.data?.length"> -->
              <!-- <ng-container *ngIf="!chartLoading"> -->

              <p class="fs12 grey1 new-number-of-applicants" *ngIf="barChartDataobj.data?.length">Number of Applicants</p>
              <!-- <div class=""> -->
                <!-- <div class=""> -->

                  <!-- <ng-container > -->
                    <!-- *ngIf="anotherChartRendering2.length > 5" -->
                  <!-- <ng-container *ngIf="anotherChartRendering2.length > 5"> -->
                    <div class="dummy-chart white-background p16">
                      <div class="containerBody1">
                        <div class=" myy-scrollbar--container">
                          <div class="my-own-container-body" #stagesChartContainer id="stagesChartContainer">
                            <canvas #stagesCanvas></canvas>
                          </div>
                            </div>
                      </div>
                     </div>
              <!-- </ng-container> -->
            <!-- </ng-container> -->
                        <!-- </ng-container> -->

                    <!-- <ng-container *ngIf="anotherChartRendering2.length < 5">
                  <canvas
                    class="ps-4"
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [type]="barChartType"
                    [plugins]="barChartPlugins"

                  >
                  </canvas>
                  </ng-container> -->
                <!-- </div> -->
              <!-- </div> -->
            </div>
          </div>
          <div class="tab-pane fade" [ngClass]="{ 'show active': stagesActiveTab === 'Country' }" id="stages-country" role="tabpanel" aria-labelledby="stages-country-tab">
            <div class="small--chart-part align-items-center position-relative">
              <ng-container *ngIf="chartLoading">
                <div class='loaderrrr'>
                  <div class='loader--text'></div>
                </div>
              </ng-container>
              <ng-container *ngIf="!chartLoading">
              <p class="fs12 grey1 new-number-of-applicants">Number of Applicants</p>

              <div class=" dummy-chart white-background p16">
                <!-- <ng-container *ngIf="anotherChartRendering2.length > 5"> -->
                <div class="containerBody">
                  <div class="my-own-container-body2" #stagesChartContainer56 id="stagesChartContainer56">
                    <canvas #stagesCanvas56></canvas>
                  </div>
                </div>

                <!-- </ng-container> -->


                <!-- <div class=""> -->
                  <!-- <ng-container *ngIf="anotherChartRendering2.length < 5">
                  <canvas
                    class="ps-4"
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [type]="barChartType"
                    [plugins]="barChartPlugins"

                  >
                  </canvas>
                  </ng-container> -->
                <!-- </div> -->
              </div>
              </ng-container>
            </div>
          </div>
          <div class="tab-pane fade" [ngClass]="{ 'show active': stagesActiveTab === 'Campus' }" id="stages-campus" role="tabpanel" aria-labelledby="stages-campus-tab">
            <div class="small--chart-part align-items-center position-relative">
              <ng-container *ngIf="chartLoading">
                <div class='loaderrrr'>
                  <div class='loader--text'></div>
                </div>
              </ng-container>
              <ng-container *ngIf="!chartLoading">
              <p class="fs12 grey1 new-number-of-applicants">Number of Applicants</p>
              <div class=" dummy-chart m-2">
                <!--  style="min-width:800px" -->
                <!-- <ng-container> -->
                  <!-- *ngIf="anotherChartRendering2.length > 5" -->
                <div class="ps-4 containerBody stages-container--body">
                  <div class="my-own-container-body2" #stagesChartContainer3 id="stagesChartContainer3">
                    <canvas #stagesCanvas3></canvas>
                  </div>
                </div>
              </div>
              </ng-container>
                <!-- </ng-container> -->
                <!-- <div class=""> -->
                  <!-- <ng-container *ngIf="anotherChartRendering2.length < 5">
                  <canvas
                    class="ps-4"
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [type]="barChartType"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                  </ng-container> -->
                <!-- </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="tab-content" id="stagesTabContent">
          <div
            class="tab-pane fade show active"
            id="stages-brand"
            role="tabpanel"
            aria-labelledby="stages-brand-tab"
          >

                <!-- <ng-container > -->
                  <!-- *ngIf="anotherChartRendering2.length > 5" -->
                <!-- <ng-container *ngIf="anotherChartRendering2.length > 5"> -->
                  <!-- <div class="dummy-chart white-background p16">
                    <div class="containerBody stages-container--body">
                      <div class="my-own-container-body2" #stagesChartContainer id="stagesChartContainer">
                        <canvas #stagesCanvas></canvas>
                        </div>
                        </div>
                        </div> -->
                      <!-- </ng-container> -->

                  <!-- <ng-container *ngIf="anotherChartRendering2.length < 5">
                <canvas
                  class="ps-4"
                  baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [type]="barChartType"
                  [plugins]="barChartPlugins"

                >
                </canvas>
                </ng-container> -->
              <!-- </div> -->
            <!-- </div> -->
          </div>
          </div>
          <div
            class="tab-pane fade"
            id="stages-country"
            role="tabpanel"
            aria-labelledby="stages-country-tab"
          >
          <div class="small--chart-part align-items-center">
            <!-- <p class="fs12 grey1 new-number-of-applicants">Number of Applicants</p> -->

            <!-- <div class=" ms-3 chart--comtainer-new" style="overflow-x: scroll; width:1200px"> -->
              <!-- <div class="containerBody">
                <div class="" #stagesChartContainer56 id="stagesChartContainer56" style="width: 100% !important;">
                  <canvas #stagesCanvas56></canvas>
                </div>
              </div> -->
              <!-- </ng-container> -->


              <!-- <div class=""> -->
                <!-- <ng-container *ngIf="anotherChartRendering2.length < 5">
                <canvas
                  class="ps-4"
                  baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [type]="barChartType"
                  [plugins]="barChartPlugins"

                >
                </canvas>
                </ng-container> -->
              <!-- </div> -->
            </div>
          <!-- </div> -->
          </div>
          <div
            class="tab-pane fade"
            id="stages-campus"
            role="tabpanel"
            aria-labelledby="stages-campus-tab"
          >
          <div class="small--chart-part align-items-center">
            <!-- <p class="fs12 grey1 new-number-of-applicants">Number of Applicants</p> -->
            <div class=" dummy-chart">
              <!--  style="min-width:800px" -->
              <!-- <ng-container> -->
                <!-- *ngIf="anotherChartRendering2.length > 5" -->
              <!-- <div class="ps-4 containerBody stages-container--body">
                <div class="my-own-container-body2" #stagesChartContainer3 id="stagesChartContainer3">
                  <canvas #stagesCanvas3></canvas>
                </div>
              </div> -->
              <!-- </ng-container> -->
              <div class="">
                <!-- <ng-container *ngIf="anotherChartRendering2.length < 5">
                <canvas
                  class="ps-4"
                  baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [legend]="barChartLegend"
                  [type]="barChartType"
                  [plugins]="barChartPlugins"
                >
                </canvas>
                </ng-container> -->
              </div>
            </div>
          </div>
      </div>

    <!-- </div> -->
    <!-- </div> -->
  </section>

  <!-- Source Of Hiring Job wise -->
  <section class="dasahboard-sourceOfHire new-source-of-hiring mt-3">
    <!-- source of hiring main new code -->
    <div class="d-xl-flex align-items-center justify-content-between" style="gap: 30px !important;">
    <div class="chart-head white-background br8 p16 my-chart--head">
      <div class="d-flex align-items-center justify-content-between">
        <p class="fs14 fw600 secondary-black">Source Of Hiring Job Wise</p>
        <ul class="nav nav-tabs" id="sourceOfHireTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': sourceHireActiveTab === 'Number' }"
              (click)="setHireActiveTab('Number')"
              id="number-tab"
              type="button"
              role="tab"
              aria-controls="number"
               [disabled]="tabsButtonDisabled"
            >
              Number
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': sourceHireActiveTab === 'Percentage' }"
              (click)="setHireActiveTab('Percentage')"
              id="percent-tab"
              type="button"
              role="tab"
              aria-controls="percent"
               [disabled]="tabsButtonDisabled"
            >
              Percent
            </button>
          </li>
        </ul>
      </div>
      <div class="mx-5 mt-3 position-relative">
        <ng-container *ngIf="mytotalCount == 0">
          <div class="chart-no-data-found charts-cards-1">
            <h1> No Data Found </h1>
          </div>
        </ng-container>
        <ng-container *ngIf="mytotalCount != 0">
        <div class="hiring-chart-div">
          <!-- <div class='loaderrrr'>
            <div class='loader--text'></div>
          </div> -->
          <p class="totalData fs14 fw600">Total Jobs <br> {{mytotalCount}} </p>
        <p-chart type="doughnut" [data]="data" [options]="myOwnOptions" />
      </div>
      </ng-container>
      </div>
    </div>

    <div class="chart-head white-background br8 p16 my-chart--head">
      <div class="d-flex align-items-center justify-content-between">
        <p class="fs14 fw600 secondary-black">Source Of Hiring Applicant wise</p>
      <ul class="nav nav-tabs" id="sourceHireActiveTab1" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': sourceHireActiveTab1 === 'Number' }"
              (click)="setHireActiveTab1('Number')"
              id="number-tab1"
              type="button"
              role="tab"
              aria-controls="number"
               [disabled]="tabsButtonDisabled"
            >
              Number
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': sourceHireActiveTab1 === 'Percentage' }"
              (click)="setHireActiveTab1('Percentage')"
              id="percent-tab1"
              type="button"
              role="tab"
              aria-controls="percent"
               [disabled]="tabsButtonDisabled"
            >
              Percent
            </button>
          </li>
        </ul>
      </div>
      <div class="mx-5 mt-3 position-relative">
        <ng-container *ngIf="myTotalCount2 == 0">
        <div class="chart-no-data-found charts-cards-1">
            <h1> No Data Found </h1>
        </div>
      </ng-container>
      <ng-container *ngIf="myTotalCount2 != 0">
        <div class="hiring-chart-div">
          <p class="totalData fs14 fw600">Total Applicants <br> {{myTotalCount2}} </p>
        <p-chart type="doughnut" [data]="data1" [options]="myOwnOptions2" />
      </div>
      </ng-container>
      </div>
    </div>
    </div>
    <!-- source of hiring main new code -->

   <!-- <div class="dashboard-chart p16">
      <div class="chart-filter-btn">
        <div class="chart-head">
          <div class="d-flex align-items-center justify-content-between">
            <p class="fs14 fw600 secondary-black">Source Of Hiring Job wise</p>
            <ul class="nav nav-tabs" id="sourceOfHireTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  (click)="GetSourceHire(mainObject, 'Number')"
                  id="number-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#number"
                  type="button"
                  role="tab"
                  aria-controls="number"
                  aria-selected="true"
                >
                  Number
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  (click)="GetSourceHire(mainObject, 'Percentage')"
                  id="percent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#percent"
                  type="button"
                  role="tab"
                  aria-controls="percent"
                  aria-selected="false"
                >
                  Percent
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="line my-2"></div>
        <div class="chart-container">
          <div class="tab-content" id="sourceOfHireContent">
            <div
              class="tab-pane fade show active"
              id="number"
              role="tabpanel"
              aria-labelledby="number-tab"
            >
            <div class="small--chart-part d-flex align-items-center">
              <p class="fs12 grey1 new-number-of-applicants">Number of Applicants</p>
              <div class="">
                <div class="containerBody">
                  <canvas
                  class="ps-4"
                    baseChart
                    [datasets]="barChartData1"
                    [labels]="barChartLabels1"
                    [options]="barChartOptions1"
                    [legend]="pbarChartLegend"
                    [type]="pbarChartType"
                    [plugins]="barChartPlugins"

                  >
                  </canvas>
                </div>
              </div>
            </div>
            </div>
            <div
              class="tab-pane fade"
              id="percent"
              role="tabpanel"
              aria-labelledby="percent-tab"
            >
            <div class="small--chart-part d-flex align-items-center">
              <p class="fs12 grey1 new-number-of-applicants">Number of Applicants</p>
              <div class="">
                <div class="containerBody">
                  <canvas
                    class="mt-4 ps-4 w-100"
                    baseChart
                    [datasets]="barChartDataPercentage"
                    [labels]="barChartLabelsPercentage"
                    [options]="barChartOptionsPercentage"
                    [legend]="barChartLegendPercentage"
                    [type]="barChartTypePercentage"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>  -->
  </section>
  <!-- Source Of Hiring Job wise -->

  <!-- Source Of Hiring Applicant wise -->
  <!-- <section class="dasahboard-sourceOfHire white-background br8 mt-3">
    <div class="dashboard-chart p16">
      <div class="chart-filter-btn">
        <div class="chart-head">
          <div class="d-flex align-items-center justify-content-between">
            <p class="fs14 fw600 secondary-black">Source Of Hiring Applicant wise</p>
            <ul class="nav nav-tabs" id="sourceOfHireTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  (click)="GetSourceOfHireApplicantsCount(mainObject, 'Number')"
                  id="number-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#number"
                  type="button"
                  role="tab"
                  aria-controls="number"
                  aria-selected="true"
                >
                  Number
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  (click)="GetSourceOfHireApplicantsCount(mainObject, 'Percentage')"
                  id="percent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#percent"
                  type="button"
                  role="tab"
                  aria-controls="percent"
                  aria-selected="false"
                >
                  Percent
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="line my-2"></div>
        <div class="chart-container">
          <div class="tab-content" id="sourceOfHireContent">
            <div
              class="tab-pane fade show active"
              id="number"
              role="tabpanel"
              aria-labelledby="number-tab"
            >
            <div class="small--chart-part d-flex align-items-center">
              <p class="fs12 grey1 new-number-of-applicants">Number of Applicants</p>
              <div class="">
                <div class="containerBody">
                  <canvas
                  class="ps-4"
                    baseChart
                    [datasets]="barChartData10"
                    [labels]="barChartLabels10"
                    [options]="pbarChartOptions10"
                    [plugins]="barChartPlugins"
                    [legend]="pbarChartLegend"
                    [type]="pbarChartType"

                  >
                  </canvas>
                </div>
              </div>
            </div>
            </div>
            <div
              class="tab-pane fade"
              id="percent"
              role="tabpanel"
              aria-labelledby="percent-tab"
            >
            <div class="small--chart-part d-flex align-items-center">
              <p class="fs12 grey1 new-number-of-applicants">Number of Applicants</p>
              <div class="">
                <div class="containerBody">
                  <canvas
                    class="mt-4 ps-4 w-100"
                    baseChart
                    [datasets]="barChartDataPercentage1"
                    [labels]="barChartLabelsPercentage1"
                    [options]="barChartOptionsPercentage1"
                    [legend]="barChartLegendPercentage"
                    [type]="barChartTypePercentage"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- Source Of Hiring Applicant wise -->



  <!-- <section class="dashboard-hiringTrend white-background p16 br8 my-3">
        <div class="d-md-flex w-100 gx-2">
            <aside class=" p16">
                <p class="fs16 fw600 black">Overall</p>
                <div class="line"></div>
                <div *ngFor="let country of countries" class="field-checkbox">
                    <p-checkbox [label]="country.name" name="group" [value]="country" [(ngModel)]="selectedCountries" class="grey fs14"></p-checkbox>
                </div>
            </aside>
            <div class="w-100">
                <div class="dashboard-chart ml-2 p16">
                    <div class="chart-filter-btn">
                        <div class="chart-head">
                            <p class="fs18 fw600 secondary-black">Hiring Trend </p>
                            <p class="fs12 grey1 number-of-applicants number-of-applicants-hiring-trend">Number of Applicants Hired</p>
                        </div>
                        <div class="line my-3"></div>
                        <div class="chart-container">
                          <div class="month-year-tabs my-5">
                          <p-tabView>
                            <p-tabPanel header="Month">

                            </p-tabPanel>
                            <p-tabPanel header="Year">

                            </p-tabPanel>
                            </p-tabView>
                          </div>
                          <div class="ps-4">
                            <p-tabView [activeIndex]="1">
                                <p-tabPanel header="Overall">
                                    <p-chart class="w-100" type="line" [data]="data1" [options]="options1"></p-chart>
                                </p-tabPanel>
                                <p-tabPanel header="Country">
                                    <p-chart type="line" [data]="data1" [options]="options1"></p-chart>

                                </p-tabPanel>
                                <p-tabPanel header="Brand">
                                    <p-chart class="w-100" type="line" [data]="data1" [options]="options1"></p-chart>

                                </p-tabPanel>
                                <p-tabPanel header="Campus">
                                    <p-chart class="w-100" type="line" [data]="data1" [options]="options1"></p-chart>

                                </p-tabPanel>
                            </p-tabView>

                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section> -->
  <section class="dashboard-positionsGlobal white-background br8 my-3 py-0">
    <div class="row gx-2 p-2 pb-4">
      <div class="chart-head col-12 px-0">
        <div class="d-flex align-items-center justify-content-between">
          <p class="fs14 fw600 secondary-black">
            Open and Closed Jobs Globally
          </p>
          <ul class="nav nav-tabs" id="globalPositionsTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                [ngClass]="{ 'active': positionsActiveTab === 'Brand' }"
                (click)="setPositionsActiveTab('Brand')"
                id="positions-brand-tab"
                type="button"
                role="tab"
                aria-controls="positions-brand"
                 [disabled]="tabsButtonDisabled"
              >
                School
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                [ngClass]="{ 'active': positionsActiveTab === 'Country' }"
                (click)="setPositionsActiveTab('Country')"
                id="positions-country-tab"
                type="button"
                role="tab"
                aria-controls="positions-country"
                 [disabled]="tabsButtonDisabled"
              >
                Country
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                [ngClass]="{ 'active': positionsActiveTab === 'Campus' }"
                (click)="setPositionsActiveTab('Campus')"
                id="positions-campus-tab"
                type="button"
                role="tab"
                aria-controls="positions-campus"
                 [disabled]="tabsButtonDisabled"
              >
                Campus
              </button>
            </li>
          </ul>
        </div>
        <div class="line my-2"></div>
        <!-- <p
          class="fs12 grey1 number-of-applicants number-of-applicants-two"
        >
          Number of Applications
        </p> -->
      </div>
      <!-- {{barChartData2OBJ.data?.length}} -->
      <ng-container *ngIf="!barChartData2OBJ.data?.length">
        <div class="chart-no-data-found chart-no-data-found--3">
          <h1> No Data Found </h1>
        </div>
      </ng-container>
      <ng-container *ngIf="barChartData2OBJ.data?.length">

      <aside class="col-md-12 col-xl-3 ">
        <div class="position-relative">
          <p class="totalData-2">
            {{ totalPositions }}
            <span >Total <br /> Vacancies</span>
          </p>
        <p-chart type="doughnut" [data]="data2" [options]="options2"></p-chart>
      </div>
        <div class="dashboard-positionsStatus">
          <!-- <button>{{ totalClosed }} Closed</button>
          <button>{{ totalOpen }} Open</button> -->
          <div class="closed-open-positions1">
            <div class="active-card card">
              <div class="d-flex align-items-center gap-2">
                <div class="box"></div>
                <p class="fw500 fs16 blackk ml-1">{{ totalActiveJobs }}</p>
                <p class="fw500 fs16 blackk ml-1">Active Jobs</p>
              </div>
            </div>
            <div class="active-card closed-card card mt-2 mt-xxl-3">
              <div class="d-flex align-items-center gap-2">
                <div class="box"></div>
                <p class="fw500 fs16 ml-1">{{ totalClosedJobs }}</p>
                <p class="fw500 fs16 ml-1">Jobs Closed</p>
              </div>
              <div class="closed-with-applicant ml-3  mt-xxl-3 mt-2">
                <div class="d-flex align-items-center">
                  <p class="fw500 fs14">{{ totalClosedWithApplicant }}</p>
                  <p class="fw400 fs12 grey ml-2" style="line-height:18px !important">Jobs Closed with Applicant</p>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <p class="fw500 fs14">
                    {{ totalDirectlyClosedApplicants }}
                  </p>
                  <p class="fw400 fs12 grey ml-2">Jobs Directly Closed</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <button>207 On Hold</button> -->
        </div>
      </aside>
      <div class="col-md-12 col-xl-9">
        <div class="dashboard-chart ml-2 p16 pt-0 pb-2">
          <div class="chart-filter-btn">
            <div class="tab-content mt-3" id="globalPositionsTabContent">
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': positionsActiveTab === 'Brand' }" id="positions-brand" role="tabpanel" aria-labelledby="positions-brand-tab"
              >
              <div class=" align-items-center">
              <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
              <div class="">
                <div class="new-replacement-charts">
                  <!-- <ng-container> -->
                    <!-- *ngIf="anotherChartRendering.length > 5" -->
                  <!-- <div class="dummy-chart white-background p16">
                    <div class="containerBody1">
                      <div class=" myy-scrollbar--container">
                        <div class="my-own-container-body my-own-container-body" #chartContainer id="chartContainer">
                          <canvas #canvas></canvas>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class='loaderrrr' *ngIf="globalPosloadingTime">
                    <div class='loader--text'></div>
                  </div>

                  <div class="dummy-chart white-background p16" *ngIf="!globalPosloadingTime">
                    <div class="containerBody1">
                      <div class=" myy-scrollbar--container">
                        <div class="my-own-container-body" #chartContainer id="chartContainer">
                          <canvas #canvas></canvas>
                        </div>
                          </div>
                    </div>
                   </div>
                <!-- </ng-container> -->

                  <!-- <ng-container *ngIf="anotherChartRendering.length < 5">
                  <canvas
                  class="ps-4"
                  baseChart
                  [datasets]="barChartData2"
                  [labels]="barChartLabels2"
                  [options]="barChartOptions2"
                  [legend]="barChartLegend2"
                [plugins]="barChartPlugins"
                [type]="barChartType2"
                >
                </canvas>
              </ng-container> -->
                </div>
              </div>

              </div>
              </div>
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': positionsActiveTab === 'Country' }" id="positions-country" role="tabpanel" aria-labelledby="positions-country-tab"
              >
              <div class=" align-items-center my-country-chart">
                <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
                <div class="">
                  <div class="new-replacement-charts">
                    <!-- <ng-container> -->
                      <!-- *ngIf="anotherChartRendering.length > 5" -->
                      <div class='loaderrrr' *ngIf="globalPosloadingTime">
                        <div class='loader--text'></div>
                      </div>

                    <div class="dummy-chart white-background p16" *ngIf="!globalPosloadingTime">
                      <div class="containerBody1">
                        <div class=" myy-scrollbar--container">
                          <div class="my-own-container-body" #chartContainer2 id="chartContainer2">
                            <canvas #canvas2></canvas>
                            </div>
                            </div>
                      </div>
                          </div>
                        <!-- </ng-container> -->

                    <!-- <ng-container *ngIf="anotherChartRendering.length < 5">
                    <canvas
                    class="ps-4"
                    baseChart
                    [datasets]="barChartData2"
                    [labels]="barChartLabels2"
                    [options]="barChartOptions2"
                    [legend]="barChartLegend2"
                  [plugins]="barChartPlugins"
                  [type]="barChartType2"
                  >
                  </canvas>
                </ng-container> -->
                  </div>
                </div>
              </div>
              </div>
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': positionsActiveTab === 'Campus' }" id="positions-campus" role="tabpanel" aria-labelledby="positions-campus-tab"
              >
              <div class=" align-items-center my-country-chart">
              <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
              <div class="">
                <div class="new-replacement-charts">
                  <ng-container>
                    <!-- *ngIf="anotherChartRendering.length > 5" -->
                    <div class='loaderrrr' *ngIf="globalPosloadingTime">
                      <div class='loader--text'></div>
                    </div>
                    <div class="dummy-chart white-background p16" *ngIf="!globalPosloadingTime">
                      <div class="containerBody1">
                        <div class=" myy-scrollbar--container">
                          <div class="my-own-container-body" #chartContainer3 id="chartContainer3">
                            <canvas #canvas3></canvas>
                            </div>
                            </div>
                      </div>
                          </div>
                  </ng-container>

                  <!-- <ng-container *ngIf="anotherChartRendering.length < 5">
                  <canvas
                    class="ps-4"
                    baseChart
                    [datasets]="barChartData2"
                    [labels]="barChartLabels2"
                    [options]="barChartOptions2"
                    [legend]="barChartLegend2"
                  [plugins]="barChartPlugins"
                  [type]="barChartType2"
                  >
                  </canvas>
                  </ng-container> -->
                </div>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    </div>
  </section>
  <!-- <div class="dummy-chart white-background p16">
    <div class="containerBody">
      <div #chartContainer id="chartContainer">
        <canvas #canvas></canvas>
      </div>
    </div>
  </div> -->
  <!-- <div class="dummy-chart white-background p16">
    <div class="containerBody">
      <div #chartContainer3 id="chartContainer3">
        <canvas #canvas></canvas>
        </div>
        </div>
        </div> -->
  <section class="dashboard-entityBreakup white-background p16 br8 pb-0">
    <div class="chart-head">
      <div class="d-flex align-items-center justify-content-between">
        <p class="fs14 fw600 secondary-black">New Vs Replacement</p>
        <ul class="nav nav-tabs" id="entityTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': entityActiveTab === 'Brand' }"
              (click)="setEntityActiveTab('Brand')"
              id="entity-brand-tab"
              type="button"
              role="tab"
              aria-controls="entity-brand"
               [disabled]="tabsButtonDisabled"
            >
              School
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': entityActiveTab === 'Country' }"
              (click)="setEntityActiveTab('Country')"
              id="entity-country-tab"
              type="button"
              role="tab"
              aria-controls="entity-country"
               [disabled]="tabsButtonDisabled"
            >
              Country
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': entityActiveTab === 'Campus' }"
              (click)="setEntityActiveTab('Campus')"
              id="entity-campus-tab"
              type="button"
              role="tab"
              aria-controls="entity-campus"
               [disabled]="tabsButtonDisabled"
            >
              Campus
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="line my-2"></div>
    <ng-container *ngIf="!barChartData3OBJ.data?.length">
      <div class="chart-no-data-found chart-no-data-found--3">
        <h1> No Data Found </h1>
      </div>
    </ng-container>
    <ng-container *ngIf="barChartData3OBJ.data?.length">

    <div class="row gx-2">
      <aside class="col-xl-3 col-md-12 pt-2 p-4 ">
        <div class="position-relative">
        <p-chart
          type="doughnut"
          [data]="dataNew"
          [options]="options2"
        ></p-chart>
        <p class="totalData-2">
          {{ totalNewPositions }}
          <span
            >Total <br />
            Positions</span
          >
        </p>
        </div>
        <div class="dashboard-positionsStatus">
          <!-- <button>{{ totalClosed }} Closed</button>
          <button>{{ totalOpen }} Open</button> -->
          <div
            class="closed-open-positions mt-3"
          >
            <div class="active-card card mb-3">
              <div class="d-flex align-items-center gap-2">
                <div class="box"></div>
                <p class="fw500 fs14 ml-1">{{ totalNewCount }}</p>
                <p class="fw500 fs14 ml-1">New</p>
              </div>
            </div>
            <div class="active-card card">
              <div class="d-flex align-items-center gap-2">
                <div class="box replacement-color"></div>
                <p class="fw500 fs14 ml-1">{{ totalReplacementCount }}</p>
                <p class="fw500 fs14 ml-1">Replacement</p>
              </div>
            </div>
          </div>
          <!-- <button>207 On Hold</button> -->
        </div>
      </aside>
      <!-- <aside class="col-2 p16 max-heightt">
        <div class="d-flex align-items-center toggle-switch mb-4"> -->
      <!-- <label for="" class="mr-2">Department wise</label>
                    <input type="checkbox" role="switch"> -->
      <!-- </div>

        <p class="fs16 fw600 black">Showing</p>
        <div class="line"></div>
        <div *ngFor="let department of departments" class="field-checkbox">
          <p-checkbox
            [label]="department.name"
            name="group"
            [value]="department"
            [(ngModel)]="departmentWise"
            class="grey fs14"
          ></p-checkbox>
        </div>
      </aside> -->
      <div class="col-md-12 col-xl-9">
        <div class="dashboard-chart ml-2 p16 pb-0">
          <div class="chart-filter-btn new-replacement--tabss">
            <div class="chart-head">
              <!-- <p
                class="fs12 grey1 number-of-applicants number-of-applicants-four"
              >
                Number of Applicants
              </p> -->
            </div>

            <div class="tab-content" id="entityTabContent">
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': entityActiveTab === 'Brand' }" id="entity-brand" role="tabpanel" aria-labelledby="entity-brand-tab"
              >
              <div class=" align-items-center">
                <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
                <!-- <div class=""> -->
                  <div class=" new-replacement-charts">
                    <!-- <ng-container > -->
                      <!-- *ngIf="anotherChartRenderingNew.length > 5" -->
                      <div class='loaderrrr' *ngIf="newReplaceLoadingTime">
                        <div class='loader--text'></div>
                      </div>

                      <div class="dummy-chart white-background p16" *ngIf="!newReplaceLoadingTime">
                        <div class="containerBody1">
                          <div class=" myy-scrollbar--container">
                            <div class="my-own-container-body" #chartContainerNew id="chartContainerNew">
                              <canvas #canvasNew></canvas>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="anotherChartRenderingNew.length < 5">
                    <canvas
                    class="ps-4"
                    baseChart
                    [datasets]="barChartData3"
                    [labels]="barChartLabels3"
                    [options]="barChartOptions3"
                    [legend]="barChartLegend3"
                    [type]="barChartType3"
                    [plugins]="barChartPlugins"

                    >
                  </canvas>
                </ng-container> -->
                <!-- </div> -->
              </div>
              </div>
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': entityActiveTab === 'Country' }" id="entity-country" role="tabpanel" aria-labelledby="entity-country-tab"
              >
              <div class=" align-items-center my-country-chart ">
                <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
                <div class="">
                  <div class=" new-replacement-charts">
                    <!-- <ng-container> -->
                      <!-- *ngIf="anotherChartRenderingNew.length > 5" -->
                      <div class='loaderrrr' *ngIf="newReplaceLoadingTime">
                        <div class='loader--text'></div>
                      </div>
                      <div class="dummy-chart white-background p16" *ngIf="!newReplaceLoadingTime">
                        <div class="containerBody1">
                          <div class=" myy-scrollbar--container">
                            <div class="my-own-container-body" #chartContainerNew2 id="chartContainerNew2">
                              <canvas #canvasNew2></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="anotherChartRenderingNew.length < 5">
                    <canvas
                      class="ps-4"
                      baseChart
                      [datasets]="barChartData3"
                      [labels]="barChartLabels3"
                      [options]="barChartOptions3"
                      [legend]="barChartLegend3"
                      [type]="barChartType3"
                    [plugins]="barChartPlugins"

                    >
                    </canvas>
                    </ng-container> -->
                  </div>
                </div>
              </div>
              </div>
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': entityActiveTab === 'Campus' }" id="entity-campus" role="tabpanel" aria-labelledby="entity-campus-tab"
              >
              <div class=" align-items-center my-country-chart ">
                <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
                <div class="">
                  <div class=" new-replacement-charts">
                    <!-- <ng-container > -->
                      <!-- *ngIf="anotherChartRenderingNew.length > 5" -->
                      <div class='loaderrrr' *ngIf="newReplaceLoadingTime">
                        <div class='loader--text'></div>
                      </div>
                      <div class="dummy-chart white-background p16" *ngIf="!newReplaceLoadingTime">
                        <div class="containerBody1">
                          <div class=" myy-scrollbar--container">
                            <div class="my-own-container-body" #chartContainerNew3 id="chartContainerNew3">
                              <canvas #canvasNew3></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    <!-- </ng-container> -->

                    <!-- <ng-container *ngIf="anotherChartRenderingNew.length < 5">
                    <canvas
                      class="ps-4"
                      baseChart
                      [datasets]="barChartData3"
                      [labels]="barChartLabels3"
                      [options]="barChartOptions3"
                      [legend]="barChartLegend3"
                      [type]="barChartType3"
                    [plugins]="barChartPlugins"

                    >
                    </canvas>
                    </ng-container> -->
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  </section>



  <section class="dashboard-entityBreakup white-background p16 br8 pb-0 mt-2">
    <div class="chart-head">
      <div class="d-flex align-items-center justify-content-between">
        <p class="fs14 fw600 secondary-black">Academic Vs Non-Academic</p>
        <ul class="nav nav-tabs" id="entityTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': entityActiveTabAca === 'Brand' }"
              (click)="setEntityActiveTabAca('Brand')"
              id="entityAca-brand-tab"
              type="button"
              role="tab"
              aria-controls="entity-brand"
               [disabled]="tabsButtonDisabled"
            >
              School
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': entityActiveTabAca === 'Country' }"
              (click)="setEntityActiveTabAca('Country')"
              id="entityAca-country-tab"
              type="button"
              role="tab"
              aria-controls="entity-country"
               [disabled]="tabsButtonDisabled"
            >
              Country
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              [ngClass]="{ 'active': entityActiveTabAca === 'Campus' }"
              (click)="setEntityActiveTabAca('Campus')"
              id="entityAca-campus-tab"
              type="button"
              role="tab"
              aria-controls="entity-campus"
               [disabled]="tabsButtonDisabled"
            >
              Campus
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="line my-2"></div>
    <ng-container *ngIf="!barChartData3OBJAca.data?.length">
      <div class="chart-no-data-found chart-no-data-found--3">
        <h1> No Data Found </h1>
      </div>
    </ng-container>
    <ng-container *ngIf="barChartData3OBJAca.data?.length">
    <div class="row gx-2">
      <aside class="col-xl-3 col-md-12 pt-2 p-4 ">
        <div class="position-relative">
        <p-chart
          type="doughnut"
          [data]="dataNewAca"
          [options]="options2"
        ></p-chart>
        <p class="totalData-2">
          {{ totalNewPositionsAca }}
          <span
            >Total <br />
            Positions</span
          >
        </p>
        </div>
        <div class="dashboard-positionsStatus">
          <!-- <button>{{ totalClosed }} Closed</button>
          <button>{{ totalOpen }} Open</button> -->
          <div
            class="closed-open-positions mt-3"
          >
            <div class="active-card card mb-3">
              <div class="d-flex align-items-center gap-2">
                <div class="box"></div>
                <p class="fw500 fs14 ml-1">{{ totalNewCountAca }}</p>
                <p class="fw500 fs14 ml-1">Academic</p>
              </div>
            </div>
            <div class="active-card card">
              <div class="d-flex align-items-center gap-2">
                <div class="box replacement-color"></div>
                <p class="fw500 fs14 ml-1">{{ totalReplacementCountAca }}</p>
                <p class="fw500 fs14 ml-1">Non-Academic</p>
              </div>
            </div>
          </div>
          <!-- <button>207 On Hold</button> -->
        </div>
      </aside>
      <!-- <aside class="col-2 p16 max-heightt">
        <div class="d-flex align-items-center toggle-switch mb-4"> -->
      <!-- <label for="" class="mr-2">Department wise</label>
                    <input type="checkbox" role="switch"> -->
      <!-- </div>

        <p class="fs16 fw600 black">Showing</p>
        <div class="line"></div>
        <div *ngFor="let department of departments" class="field-checkbox">
          <p-checkbox
            [label]="department.name"
            name="group"
            [value]="department"
            [(ngModel)]="departmentWise"
            class="grey fs14"
          ></p-checkbox>
        </div>
      </aside> -->
      <div class="col-md-12 col-xl-9">
        <div class="dashboard-chart ml-2 p16 pb-0">
          <div class="chart-filter-btn new-replacement--tabss">
            <div class="chart-head">
              <!-- <p
                class="fs12 grey1 number-of-applicants number-of-applicants-four"
              >
                Number of Applicants
              </p> -->
            </div>

            <div class="tab-content" id="entityTabContent">
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': entityActiveTabAca === 'Brand' }" id="entity-brand" role="tabpanel" aria-labelledby="entityAca-brand-tab"
              >
              <div class=" align-items-center">
                <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
                <!-- <div class=""> -->
                  <div class=" new-replacement-charts">
                    <!-- <ng-container > -->
                      <!-- *ngIf="anotherChartRenderingNew.length > 5" -->
                      <div class='loaderrrr' *ngIf="newReplaceLoadingTimeAca">
                        <div class='loader--text'></div>
                      </div>

                      <div class="dummy-chart white-background p16" *ngIf="!newReplaceLoadingTimeAca">
                        <div class="containerBody1">
                          <div class=" myy-scrollbar--container">
                            <div class="my-own-container-body" #chartContainerNew id="chartContainerNew">
                              <canvas #canvasNewTxt></canvas>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="anotherChartRenderingNew.length < 5">
                    <canvas
                    class="ps-4"
                    baseChart
                    [datasets]="barChartData3"
                    [labels]="barChartLabels3"
                    [options]="barChartOptions3"
                    [legend]="barChartLegend3"
                    [type]="barChartType3"
                    [plugins]="barChartPlugins"

                    >
                  </canvas>
                </ng-container> -->
                <!-- </div> -->
              </div>
              </div>
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': entityActiveTabAca === 'Country' }" id="entity-country" role="tabpanel" aria-labelledby="entityAca-country-tab"
              >
              <div class=" align-items-center my-country-chart ">
                <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
                <div class="">
                  <div class=" new-replacement-charts">
                    <!-- <ng-container> -->
                      <!-- *ngIf="anotherChartRenderingNew.length > 5" -->
                      <div class='loaderrrr' *ngIf="newReplaceLoadingTimeAca">
                        <div class='loader--text'></div>
                      </div>
                      <div class="dummy-chart white-background p16" *ngIf="!newReplaceLoadingTimeAca">
                        <div class="containerBody1">
                          <div class=" myy-scrollbar--container">
                            <div class="my-own-container-body" #chartContainerNew2Aca id="chartContainerNew2Aca">
                              <canvas #canvasNew2Aca></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="anotherChartRenderingNew.length < 5">
                    <canvas
                      class="ps-4"
                      baseChart
                      [datasets]="barChartData3"
                      [labels]="barChartLabels3"
                      [options]="barChartOptions3"
                      [legend]="barChartLegend3"
                      [type]="barChartType3"
                    [plugins]="barChartPlugins"

                    >
                    </canvas>
                    </ng-container> -->
                  </div>
                </div>
              </div>
              </div>
              <div
              class="tab-pane fade" [ngClass]="{ 'show active': entityActiveTabAca === 'Campus' }" id="entity-campus" role="tabpanel" aria-labelledby="entityAca-campus-tab"
              >
              <div class=" align-items-center my-country-chart ">
                <p class="fs12 grey1 new-number-of-applicants new-number-of-applicants1">Number of Applicants</p>
                <div class="">
                  <div class=" new-replacement-charts">
                    <!-- <ng-container > -->
                      <!-- *ngIf="anotherChartRenderingNew.length > 5" -->
                      <div class='loaderrrr' *ngIf="newReplaceLoadingTimeAca">
                        <div class='loader--text'></div>
                      </div>
                      <div class="dummy-chart white-background p16" *ngIf="!newReplaceLoadingTimeAca">
                        <div class="containerBody1">
                          <div class=" myy-scrollbar--container">
                            <div class="my-own-container-body" #chartContainerNew3Aca id="chartContainerNew3Aca">
                              <canvas #canvasNew3Aca></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    <!-- </ng-container> -->

                    <!-- <ng-container *ngIf="anotherChartRenderingNew.length < 5">
                    <canvas
                      class="ps-4"
                      baseChart
                      [datasets]="barChartData3"
                      [labels]="barChartLabels3"
                      [options]="barChartOptions3"
                      [legend]="barChartLegend3"
                      [type]="barChartType3"
                    [plugins]="barChartPlugins"

                    >
                    </canvas>
                    </ng-container> -->
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </ng-container>
  </section>









  <section class="hr-performance-report-main white-background p16 br8 mt-3">
    <div class="dashboard-table">
      <div class="chart-head mb-3 d-flex align-items-center justify-content-between">
      <p class="black fw600 fs14 heading">Joiners - 2024 &nbsp; Total Joiners Count - <span class="blue-color"> {{globalJoinersTotalCOunt}} </span> </p>
      <!-- - {{globalJoinersTotalCOunt}} -->
      <ul class="nav nav-tabs" id="joinersTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{ 'active': joinerActiveTab === 'Brand' }"
            (click)="setJoinerActiveTab('Brand', 'GetGlobalJoiners')"
            id="joiners-brand-tab"
            type="button"
            role="tab"
            aria-controls="joiners-brand"
          >
            School
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{ 'active': joinerActiveTab === 'Country' }"
            (click)="setJoinerActiveTab('Country', 'GetGlobalJoinersByCountry')"
            id="joiners-country-tab"
            type="button"
            role="tab"
            aria-controls="joiners-country"
          >
            Country
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{ 'active': joinerActiveTab === 'Campus' }"
            (click)="setJoinerActiveTab('Campus', 'GetGlobalJoinersByCampus')"
            id="joiners-campus-tab"
            type="button"
            role="tab"
            aria-controls="joiners-campus"
          >
            Campus
          </button>
        </li>
      </ul>
      </div>
      <div class="line"></div>
      <!-- <div class="d-flex table-filter">
        <div class="search-inputt me-3 relative">
          <input type="text" class="w-100" placeholder="Search" />
          <img
            class="img-fluid absolute"
            src="../../../../assets/Images/search.svg"
            alt="search icon"
          />
        </div>
        <div>
          <app-pop-up
            [header]="'Second Modal Header'"
            [content]="'Content for the second modal'"
            [imageSrc]="'../../../assets/Images/filter-black.svg'"
            [buttonText]="'Filter'"
            [buttonClass]="'filters-button'"
          >
          </app-pop-up>
        </div>
      </div> -->

      <div class='loaderrrr' *ngIf="loadingTime">
        <div class='loader--text'></div>
      </div>

      <div class="table-responsive" *ngIf="!loadingTime">
        <table class="table">
          <thead>
            <tr>
              <th>
                <ng-container *ngIf="joinersBrand"> Schools </ng-container>
                <ng-container *ngIf="joinersCountry"> Country </ng-container>
                <ng-container *ngIf="joinersCampus"> Campus </ng-container>
                 </th>
              <th> <p> Jan </p> </th>
              <th> <p>Feb</p> </th>
              <th> <p>Mar</p> </th>
              <th><p>Apr</p></th>
              <th><p>May</p></th>
              <th><p>Jun</p></th>
              <th><p>Jul</p></th>
              <th><p>Aug</p></th>
              <th><p>Sep</p></th>
              <th><p>Oct</p></th>
              <th><p>Nov</p></th>
              <th><p>Dec</p></th>
              <th class="empty-tr-td"></th>
              <th><p>Total</p></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let x of globleArray; let i = index">
              <td>
                <ng-container *ngIf="joinersBrand">  {{ x.Brand_Name }} </ng-container>
                <ng-container *ngIf="joinersCountry">  {{ x.CountryName }} </ng-container>
                <ng-container *ngIf="joinersCampus">  {{ x.CampusName }} </ng-container>
              </td>
              <td><p>{{ x.Jan }}</p></td>
              <td><p> {{ x.Feb }} </p></td>
              <td><p> {{ x.Mar }} </p></td>
              <td><p> {{ x.Apr }} </p></td>
              <td><p> {{ x.May }} </p></td>
              <td><p> {{ x.Jun }} </p></td>
              <td><p> {{ x.Jul }} </p></td>
              <td><p> {{ x.Aug }} </p></td>
              <td><p> {{ x.Sep }} </p></td>
              <td><p> {{ x.Oct }} </p></td>
              <td><p> {{ x.Nov }} </p></td>
              <td><p> {{ x.Dec }} </p></td>
              <td class="empty-tr-td"></td>
              <td> <p>{{ x.TotalCount }}</p> </td>
            </tr>
            <tr>
              <td><p style="justify-content:left !important ;">Total</p></td>
              <td><p>{{overallJoinerCountsByMonth.Jan}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Feb}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Mar}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Apr}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.May}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Jun}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Jul}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Aug}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Sep}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Oct}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Nov}}</p></td>
              <td><p>{{overallJoinerCountsByMonth.Dec}}</p></td>
              <td class="empty-tr-td"></td>
              <td><p>{{overallJoinerCountsByMonth.Total}}</p></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </section>

  <section class="dasahboard-averagTat white-background br8 mt-3 position-relative">
    <div class="dashboard-chart p16">
      <div class="chart-filter-btn">
        <div class="chart-head">
          <div class="d-flex align-items-center justify-content-between">
            <p class="fs14 fw600 secondary-black">Average TAT (In days) For Brands</p>
            <!-- <ul class="nav nav-tabs" id="averageTatTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  (click)="GetAverageTAT(mainObject, 'Brand')"
                  id="average-brand-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#average-brand"
                  type="button"
                  role="tab"
                  aria-controls="average-brand"
                  aria-selected="true"
                >
                  Brand
                </button>
              </li>

            </ul> -->
          </div>
        </div>
        <div class="line my-2"></div>
        <div class="chart-container position-relative">
          <div class="tab-content" id="averageTatTabContent">
            <div
              class="tab-pane fade show active"
              id="average-brand"
              role="tabpanel"
              aria-labelledby="average-brand-tab"
            >
            <div class="small--chart-part d-flex align-items-center">
              <p class="fs12 grey1 new-number-of-applicants1 mx-3">Number of Days</p>
              <div class=" w-100">
                <div class=" w-100 TAT-canvas">

                  <p-chart type="bar" [data]="myStackedData" [options]="myStackedOptions" />

                  <!-- <ng-container *ngIf="anotherChartRenderingNew.length > 5"> -->
                    <!-- <div class="dummy-chart white-background p16">
                      <div class="containerBody">
                        <div class="my-own-container-body" #chartContainerTAT id="chartContainerTAT">
                          <canvas #canvasTAT></canvas>
                        </div>
                      </div>
                    </div> -->
                  <!-- </ng-container> -->


                  <!-- <canvas
                    class="w-100"
                    baseChart
                    [datasets]="barChartData5"
                    [labels]="barChartLabels5"
                    [options]="barChartOptions5"
                    [legend]="barChartLegend5"
                    [type]="barChartType"
                    [plugins]="barChartPlugins"
                  >
                  </canvas> -->
                  <!-- <canvas
                    class="w-100"
                    baseChart
                    [datasets]="barChartData4"
                    [labels]="barChartLabels4"
                    [options]="barChartOptions4"
                    [legend]="barChartLegend4"
                    [type]="barChartType4"
                    [plugins]="barChartPlugins"
                  >
                  </canvas> -->
                </div>
              </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="average-country"
              role="tabpanel"
              aria-labelledby="average-country-tab"
            >
            <div class="small--chart-part d-flex align-items-center">
              <p class="fs12 grey1 new-number-of-applicants1">Number of Applicants</p>
              <div class="">
                <div class=" w-100">
                  <canvas
                    class=""
                    baseChart
                    [datasets]="barChartData4"
                    [labels]="barChartLabels4"
                    [options]="barChartOptions4"
                    [legend]="barChartLegend4"
                    [type]="barChartType4"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </div>
            </div>
            <div
              class="tab-pane fade"
              id="average-campus"
              role="tabpanel"
              aria-labelledby="average-campus-tab"
            >
            <div class="" >
                <div class=" w-100">
                  <canvas
                    class=""
                    baseChart
                    [datasets]="barChartData4"
                    [labels]="barChartLabels4"
                    [options]="barChartOptions4"
                    [legend]="barChartLegend4"
                    [type]="barChartType4"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <div class="card">
    <p-chart type="bar" [data]="myStackedData" [options]="myStackedOptions" />
</div> -->


  <section class="hr-performance-report-main white-background p16 br8 mt-3 px-2">
    <div class="chart-head">
      <div class="d-flex align-items-center justify-content-between mb-2">
      <p class="black fw600 fs14">Performance Report</p>
      <div class="d-flex align-items-center gap-3">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link text-start" id="performance-brand" [ngClass]="{active: hrPerformanceVariable1}" type="button" (click)="hrBrand(1)">
            School
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" type="button" [ngClass]="{active: hrPerformanceVariable2}" (click)="hrBrand(2)">
            Country
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" type="button" [ngClass]="{active: hrPerformanceVariable3}" (click)="hrBrand(3)">
            Campus
          </button>
        </li>
      </ul>
      <!-- <div class="export-part">
        <p class="blue-color fs14 fw00 pointer"> Export </p>
      </div> -->
      </div>
    </div>
    </div>
    <div class="tab-content hr-performance-tab-content mt-2" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="hr-brand-pane"
        role="tabpanel"
        aria-labelledby="hr-brand"
        tabindex="0"
      >

      <div class='loaderrrr' *ngIf="myLoadingTime">
        <div class='loader--text'></div>
      </div>
      <ng-container *ngIf="!HRPerformanceArray.length && !myLoadingTime">
          <div class="position-relative">
          <div class="chart-no-data-found--4">
            <h1> No Data Found </h1>
          </div>
          </div>
        </ng-container>
      <ng-container *ngIf="!myLoadingTime">
      <div class="table-responsive" *ngIf="HRPerformanceArray.length">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th colspan="10">
                <p class="d-flex align-items-center justify-content-center w-100 blackk fw700">Applicants</p>
              </th>
              <th colspan="6">
                <p class="d-flex align-items-center justify-content-center w-100 blackk fw700">Jobs</p>
              </th>
            </tr>
            <tr>
              <th scope="col" class="bg-beige" *ngIf="hrPerformanceVariable3 == true" style="position: unset !important;"> <p> Country </p> </th>
              <th scope="col" class="bg-beige" *ngIf="hrPerformanceVariable3 == true"> <p> School </p> </th>
              <th scope="col" [ngClass]="{'bg-beige': hrPerformanceVariable3,'sticky': hrPerformanceVariable3}">
                <p class="d-block text-left">
                <ng-container *ngIf="hrPerformanceVariable1 == true"> {{ "Brand" }} </ng-container>
                <ng-container *ngIf="hrPerformanceVariable2 == true"> {{ "Country" }} </ng-container>
                <!-- <ng-container *ngIf="hrPerformanceVariable3 == true"> Country </ng-container>
                <ng-container *ngIf="hrPerformanceVariable3 == true"> School</ng-container> -->
                <ng-container *ngIf="hrPerformanceVariable3 == true">  {{ "Campus" }} </ng-container>
              </p>
              </th>
              <th scope="col"> <p> Total Applicants </p> </th>
              <th scope="col"> <p> New </p> </th>
              <th scope="col"> <p> Screened </p> </th>
              <th scope="col"> <p> Interview </p> </th>
              <th scope="col"> <p> Pre Offer </p>   </th>
              <th scope="col"> <p> Offer </p>   </th>
              <th scope="col"> <p> Selected (Offer Accepted) </p>   </th>
              <th scope="col"> <p> Onboarded (Post Offer) </p>   </th>
              <th scope="col"> <p> Offer Rejected </p>   </th>
              <th scope="col"> <p> Rejected by HR </p>   </th>
              <th scope="col"> <p> Total Jobs </p>   </th>
              <th scope="col"> <p> Active Jobs </p>   </th>
              <th scope="col"> <p> Closed Jobs </p>   </th>
              <th scope="col"> <p> Jobs Closed Without Applicant </p>   </th>
              <th scope="col"> <p>  No. of Open Vacancies </p>  </th>
              <th scope="col"> <p>  No. of Closed Vacancies </p>  </th>
            </tr>
          </thead>

          <tbody>

            <tr *ngFor="let x of HRPerformanceArray; let i = index">
              <!-- <td scope="row" class="text-start" *ngIf="hrPerformanceVariable3 == true">
                <p class=" d-block">
                  {{x.CountryName}}....
            </p>
            </td> -->
              <!-- <td *ngIf="hrPerformanceVariable3 == true"> <p> {{x.BrandName}}.. </p>  </td> -->
              <!-- <td *ngIf="hrPerformanceVariable3 == true"> <p> {{x.CountryName}} </p>  </td> -->
              <td scope="col" class="bg-beige" *ngIf="hrPerformanceVariable3"> <p> {{x.CountryName || '--'}} </p> </td>
              <td scope="col" class="bg-beige" *ngIf="hrPerformanceVariable3"> <p class="fw600"> {{x.BrandName}} </p> </td>
              <td scope="row" class="text-start" [ngClass]="{'bg-beige': hrPerformanceVariable3}">
                <p class="fw600 d-block">
                  <ng-container *ngIf="hrPerformanceVariable1 == true">{{x.BrandName}}</ng-container>
              <ng-container *ngIf="hrPerformanceVariable2 == true">{{x.Country}}</ng-container>
              <ng-container *ngIf="hrPerformanceVariable3 == true"> {{x.CampusName}} </ng-container>
            </p>
            </td>
              <td> <p> {{x.TotalApplicants}} </p>  </td>
              <td> <p> {{x.NEW}} </p>  </td>
              <td> <p> {{x.SCREENING}} </p> </td>
              <td> <p> {{x.INTERVIEW}} </p> </td>
              <td> <p> {{x.PREOFFER}} </p>  </td>
              <td> <p> {{x.OFFER}} </p>  </td>
              <td> <p> {{x.Selected}} </p>  </td>
              <td> <p> {{x.Onboarded}} </p>  </td>
              <td> <p> {{x.OfferRejected}} </p>  </td>
              <td> <p> {{x.RejectedbyHR }} </p>  </td>
              <td> <p> {{x.TotalJobs}} </p>  </td>
              <td> <p> {{x.ActiveJobs}} </p>  </td>
              <td> <p> {{x.ClosedJobs}} </p>  </td>
              <td> <p> {{x.Jobsclosedwithoutapplicant}} </p>  </td>
              <td> <p> {{x.NoOfVacancies}} </p>  </td>
              <td> <p> {{x.NoOfClosedVacancies}} </p>  </td>
            </tr>
            <tr class="second-dashboard-tr">
              <td *ngIf="hrPerformanceVariable3 == true" style="background: antiquewhite;position: unset;"></td>
              <td style="background: antiquewhite;position: unset;" *ngIf="hrPerformanceVariable3 == true"></td>
              <td style="background: antiquewhite;position: sticky !important;left: -1px;"><p class="fw600" style="justify-content: left !important;">Total</p></td>
              <td><p>{{totalApplicantscount}}</p></td>
              <td><p>{{newcount}}</p></td>
              <td><p>{{screeningcount}}</p></td>
              <td><p>{{interviewcount}}</p></td>
              <td><p>{{preoffercount}}</p></td>
              <td><p>{{OFFERcount}}</p></td>
              <td><p>{{Selectedcount}}</p></td>
              <td><p>{{Onboardedcount}}</p></td>
              <td><p>{{OfferRejectedcount}}</p></td>
              <td><p>{{RejectedbyHRcount}}</p></td>
              <td><p>{{totalJobscount}}</p></td>
              <td><p>{{ActiveJobscount}}</p></td>
              <td><p>{{ClosedJobscount}}</p></td>
              <td><p>{{Jobsclosedwithoutapplicantcount}}</p></td>
              <td><p>{{NoOfVacanciescount}}</p></td>
              <td><p>{{NoOfClosedVacanciescount}}</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
      </div>
    </div>
  </section>
<!-- </p-tabPanel>
</p-tabView> -->
<div>
  <router-outlet></router-outlet>
</div>
<!-- <ng-container *ngIf="activeTabIndexDetailsViewUpdate === 0">
  <h2 class="fs22 fw600 primary_txt mb-4">Hiring Summary</h2>
      <div class="hiring-summary">
        <div
          class="card_Data"
          *ngFor="let card of hiringSummary"
          [ngClass]="{ large: card.hasMoreContent }"
        >
          <div class="card_header_Data">
            <span class="fs14 fw600 mrg_22"
              >{{ card.title }}
              <span>
                <img
                  *ngIf="card.showTooltip"
                  src="./assets/Images/tooltip-black-info.svg"
                  alt="Info"
                  class="tooltip-icon ml-1"
                  pTooltip=" information"
                  tooltipPosition="top"
                />
              </span></span
            >
            <button
              *ngIf="card.createButton"
              class="create-btn fs14 fw600"
              (click)="trfRedirect()"
            >
              + Create
            </button>
          </div>
          <div class="card_content_Data">
            <div *ngFor="let stat of card.stats" class="stat">
              <p class="d-flex fs26 fw600 primary_txt">
                {{ stat.value }}
                <img
                  *ngIf="stat.showTooltip"
                  src="./assets/Images/tooltip-black-info.svg"
                  alt="Info"
                  class="tooltip-icon ml-1"
                  pTooltip="information"
                  tooltipPosition="top"
                />
              </p>
              <p class="fs12 fw500 secondary_txt mt-2">
                {{ stat.label }}
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <button class="action-btn fs14 fw600" (click)="card.command()">
              {{ card.buttonText }}
            </button>
          </div>
        </div>
      </div>

      <div class="campus_recruiters mb-4">
        <div class="recruiter-container">
          <div class="recruiter-grid table_scrollbar">
            <div class="recruiter_header">
              <h4 class="fs16 fw600">
                Campus Recruiters ({{ recruiters.length }})
              </h4>
            </div>

            <div class="recruiter-list">
              <div
                class="recruiter-card"
                *ngFor="let recruiter of recruiters"
              >
                <div
                  class="profile d-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <p-avatar
                      image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
                      styleClass="mr-2"
                      size="large"
                      shape="circle"
                    />
                    <h4 class="fs14 fw400 primary_txt">
                      {{ recruiter.name }}
                    </h4>
                  </div>
                  <div>
                    <img
                      src="./assets/Images/message-icon.svg"
                      alt="messages_icon"
                    />
                  </div>
                </div>
                <p class="fs12 fw500 secondary_txt">
                  {{ recruiter.email }}
                </p>
                <p class="fs12 fw500 secondary_txt">
                  {{ recruiter.phone }}
                </p>
                <p class="location fs12 fw500 secondary_txt">
                  {{ recruiter.location }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="explore-card d-flex align-items-center justify-content-between"
          >
            <div class="explore_img">
              <img
                src="./assets/Images/explore-gsg-img.svg"
                alt="Explore_image"
              />
            </div>
            <div>
              <p class="fs16 fw600 primary_txt mb-3">
                Want to find more you are looking for?
              </p>
              <button type="button" class="fs14 fw600" (click)="navigateToCareer(path)">
                Explore GSG Careers
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="my_activites mb-4">
        <h4 class="fs22 fw600 primary_txt mb-4">My Activities</h4>
        <div class="activities-grid">
          <div class="bg-white activites_cards">
            <div class="pad_10_16">
              <h4 class="fs18 fw600 primary_txt">My Task ({{allTasksOriginalContent.count ? allTasksOriginalContent.count : allTasksOriginalContent.length}})</h4>
            </div>
            <div class="bdr_btm"></div>
            <div class="pad_10_16">
              <div class="tabs-search-container mb-3">
                <p-tabView
                  [(activeIndex)]="activeTabIndexTaskDetails"
                  class="tabs-section" (onChange)="onMyActivitesTabChange(activeTabIndexTaskDetails)"
                >
                  <p-tabPanel
                    *ngFor="let tab of activitesTabs; let i = index"
                  >
                    <ng-template pTemplate="header">
                      <span>{{ tab.title }} </span>
                      <span class="tabs_count">{{ tab.count }} </span>
                    </ng-template>
                  </p-tabPanel>
                </p-tabView>

                <div class="search-inputt relative">
                  <input
                    type="text"
                    class="w-100"
                    placeholder="Search"
                    [(ngModel)]="searchTaskItem"
                    (input)="searchTemplates()"
                  />
                  <img
                    class="img-fluid absolute search-icon"
                    src="./assets/Images/search.svg"
                    alt="search icon"
                  />
                  <svg
                    (click)="clearSearch()"
                    *ngIf="searchTaskItem"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="cross-iconn pointer bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </div>
              </div>

              <div class="custom_scroll pad_20_22">
                <ng-container *ngIf="
                activitesTabs[activeTabIndexTaskDetails]?.filteredContent?.length > 0;else noMyTaskDataTemplate"
              class="pad_20_22">
                  <div
                    *ngFor="
                      let task of activitesTabs[activeTabIndexTaskDetails]
                        .filteredContent
                    "
                    class="task-item d-flex justify-content-between mb-2 pb-1"
                  >
                    <div class="d-flex align-items-center">
                      <div class="task-icon mr-2">
                        <img
                          src="./assets/Images/Mention-mytasks.svg"
                          alt="mention"
                        />
                      </div>
                      <div class="task-info">
                        <div class="d-flex align-items-center">
                          <div>
                            <h6 class="fs14 fw600 primary_txt mb-1">
                              {{ task.message  }}
                            </h6>
                            <div class="d-flex">
                              <span class="fs12 fw400 primary_txt mr-1">
                                {{ task.miniMessage}}
                                <img (click)="routeToInterviews(task.message)"
                                src="./assets/Images/mytasks-send.svg"
                                alt="send" class="ml-2 pointer"
                              />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="task-time">
                      <span class="fs12 fw400 primary_txt"
                        >{{ task.date }} | {{ task.time }}</span
                      >
                    </div>
                  </div>
              </ng-container>
                <ng-template #noMyTaskDataTemplate>
                  <div class="text-center p-3">{{emptyTaskMessage ? emptyTaskMessage : 'No tasks found'}}.</div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="bg-white activites_cards">
            <div class="pad_10_16 d-flex align-items-center justify-content-between">
              <h4 class="fs18 fw600 primary_txt">My Interviews</h4>

              <div *ngIf="activeTab?.content?.data?.length > 5">
              <button type="button" class="view_all fs14 fw600" (click)="toggleViewAll()">{{ showAllInterviews ? 'View Less' : 'View All' }}</button>
            </div>
            </div>
            <div class="bdr_btm"></div>
            <div class="pad_10_16">
              <div class="tabs-search-container mb-3 d-flex align-items-center justify-content-between flex-wrap">
                <p-tabView [(activeIndex)]="activeTabIndexInterviewDetails" class="tabs-section" (onChange)="onTabChange($event)">
                  <p-tabPanel *ngFor="let tab of interviewsTabs; let i = index">
                    <ng-template pTemplate="header">
                      {{ tab.title }}
                      <span class="tabs_count">{{ tab.count }}</span>
                    </ng-template>
                  </p-tabPanel>
                </p-tabView>

                <div class="search-inputt relative ml-3">
                  <input
                    type="text"
                    class="w-100"
                    placeholder="Search"
                    [(ngModel)]="searchitem"
                    (input)="searchInterviews($event)"
                  />
                  <img class="img-fluid absolute search-icon" src="./assets/Images/search.svg" alt="search icon" />
                  <svg
                    *ngIf="searchInterviewItem && searchitem.length > 0"
                    (click)="clearInterviewSearch()"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="cross-iconn pointer bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </div>
              </div>

              <div  class="custom_scroll pad_20_22">
                <ng-container *ngIf="activeTab?.content?.data?.length > 0; else noDataTemplate">
                  <div *ngFor="let interview of getDisplayedInterviews()">
                    <div class="d-flex align-items-center justify-content-between mb-2 pb-1" style="gap: 10px;">
                      <div class="d-flex align-items-center">
                        <div class="task-icon mr-2">
                          <img src="./assets/Images/interviews-logo.svg" alt="mention" />
                        </div>
                        <div class="applicant_style width-max">
                          <h6 class="fs14 fw600 primary_txt mb-1">{{ interview?.applicantName }}</h6>
                          <div class="fs12 fw400 primary_txt">
                            {{ interview?.dateRange | date:'dd/MM/yyyy' }} | {{ getFormattedTime(interview?.meetingTIme) }}
                          </div>
                        </div>
                      </div>
                      <div class="stage_campus_style">
                        <div class="d-flex align-items-center jst_end">
                          <span class="fs12 fw500 light_grey mr-1 mb-1">
                            Stage: {{ interview?.stage }} | {{ interview?.interviewType }}
                          </span>
                        </div>
                        <div class="fs12 fw400 primary_txt">
                          {{ interview?.campus }} | {{ interview?.jobTitle }} | {{ interview?.jobCode }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-template #noDataTemplate>
                  <div class="text-center p-3">No interviews found.</div>
                </ng-template>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="my_referals bg-white mb-4 pb-2 w-100">
        <div class="text-center">
          <img
            src="../../../assets/Images/no-referals.svg"
            alt="no_referals"
          />
          <p class="fs18 fw600 primary_txt my-1">My Referal</p>
          <p class="fs14 fw600" style="color: #4b5565">
            No Referral added yet
          </p>
        </div>
      </div>
</ng-container> -->
</div>
</main>
