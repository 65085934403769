<div class="loader" *ngIf="employeeHomepage"></div>
<main class="employee_homepage" *ngIf="!employeeHomepage">
  <section class="employee_dashboard">
    <div
      class="employee_head d-flex align-items-center justify-content-between my-4"
    >
      <h2 class="fs26 fw600 primary_txt">Dashboard</h2>
      <div class="">
        <span class="fs22 fw600 primary_txt">Good Morning, {{userDetails.UserFullName}} 👋 </span>
      </div>
      <div class="d-flex align-items-center">
        <div class="tabs-container">
          <div *ngIf="roleExists || !roleExists"
            class="tab"
            [class.active]="activeRoute === '/employee-dashboard'|| activeRoute === '/'">
            Home Page
          </div>
        <div *ngIf="!roleExists"
        class="tab"
        [routerLink]="'/dashboard-reports'"
        (click)="goToHomePage()"
        [class.active]="activeRoute === '/dashboard-reports'">
        HR Dashboard
        </div>

        </div>
        <div class="btn_hover_btn ml-2">
          <p-button
            class="employee_filter pointer d-flex align-items-center"
            (click)="openFilter()"
          >
            <img
              alt="filter icon"
              class="blue_filter_icon ng-star-inserted"
              src="./assets/img/blue-filter-icon.svg"
            />
            <!-- <span class="filter-button primary_txt mr-2">Filter</span>
            <span class="selected-filter-count"> 2 </span> -->
          </p-button>
        </div>
      </div>
    </div>

    <!-- <div class="mb-3 d-flex align-items-center">
      <img src="./assets/Images/calender-icon.svg" alt="" />
      <span class="fs14 fw600 primary_txt ml-2">{{ today | date: 'EEEE, dd MMMM' }}</span>
    </div> -->



    <div class="dashboard_tabs">
      <!-- <p-tabView [(activeIndex)]="activeTabIndexDetailsViewUpdate" (onChange)="onTabChangeTab($event)">
        <p-tabPanel *ngFor="let tab of tabsDetails; let i = index">
          <ng-template pTemplate="header">
            {{ tab.title }}
            <span class="tabs_count">{{ tab.count }}</span>
          </ng-template> -->


  <ng-container>
  <!-- <h2 class="fs22 fw600 primary_txt mb-4">Hiring Summary</h2> -->
      <div class="hiring-summary" *ngIf="!skeletonTwo">
        <div
          class="card_Data"
          *ngFor="let card of hiringSummary"
          [ngClass]="{ large: card.hasMoreContent }"
        >
          <div class="card_header_Data">
            <span class="fs14 fw600 mrg_22"
              >{{ card.title }}
              <span>
                <img
                  *ngIf="card.showTooltip"
                  src="./assets/Images/tooltip-black-info.svg"
                  alt="Info"
                  class="tooltip-icon ml-1"
                  pTooltip=" information"
                  tooltipPosition="top"
                />
              </span></span
            >
            <button
              *ngIf="card.createButton"
              class="create-btn fs14 fw600"
              (click)="trfRedirect()"
            >
              + Create
            </button>
          </div>
          <div class="card_content_Data">
            <div *ngFor="let stat of card.stats" class="stat">
              <p class="d-flex fs26 fw600 primary_txt">
                {{ stat.value }}
                <img
                  *ngIf="stat.showTooltip"
                  src="./assets/Images/tooltip-black-info.svg"
                  alt="Info"
                  class="tooltip-icon ml-1"
                  pTooltip="information"
                  tooltipPosition="top"
                />
              </p>
              <p class="fs12 fw500 secondary_txt mt-2">
                {{ stat.label }}
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <button class="action-btn fs14 fw600" (click)="card.command()">
              {{ card.buttonText }}
            </button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="skeletonTwo">
        <div class="my-loading-skeleton w-100 d-flex flex-wrap">
          <div class="card_Data skeleton-card" *ngFor="let index of [0, 1, 2, 3]; let i = index">
            <!-- Card Header Skeleton -->
            <div class="card_header_Data d-flex justify-content-between align-items-center">
              <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
            </div>

            <!-- Card Content Skeleton -->
            <div class="card_content_Data">
              <div *ngFor="let _ of getSkeletonFieldCount(i)" class="stat">
                <p-skeleton width="3rem" height="3rem" styleClass="mb-2"></p-skeleton>
                <p-skeleton height="1rem" styleClass="mb-2" />
              </div>
            </div>

            <!-- Action Button Skeleton -->
            <div class="d-flex justify-content-start">
              <p-skeleton width="8rem" height="2rem" borderRadius="6px"></p-skeleton>
            </div>
          </div>
        </div>
      </ng-container>


      <div class="campus_recruiters mb-4">
        <div class="recruiter-container">
          <div class="recruiter-grid table_scrollbar">
            <div class="recruiter_header mb-3">
              <h4 class="fs16 fw600">
                Campus Recruiters ({{ recruiters.length }})
              </h4>
            </div>

            <div class="recruiter-list">
              <div
                class="recruiter-card"
                *ngFor="let recruiter of recruiters"
              >
                <div
                  class="profile d-flex align-items-center justify-content-between mb-2 pb-1"
                >
                  <div class="d-flex align-items-center">
                    <p-avatar
                      image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png"
                      styleClass="mr-2"
                      size="large"
                      shape="circle"
                    />
                    <h4 class="fs14 fw400 primary_txt">
                      {{ recruiter.recruiterName }}
                    </h4>
                  </div>
                  <div>
                    <img class="ml-2"
                      src="./assets/Images/message-icon.svg"
                      alt="messages_icon"
                    />
                  </div>
                </div>
                <p class="fs12 fw500 secondary_txt mb-1">
                  {{ recruiter.email }}
                </p>
                <p class="fs12 fw500 secondary_txt mb-1">
                  {{ recruiter.phone }}
                </p>
                <div class="d-flex align-items-center">
                  <p class="location fs12 fw500 secondary_txt">
                    Role:{{ role }}
                  </p>
                  <p class="fs14 fw600"></p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="explore-card d-flex align-items-center relative" style="gap:36px"
          >
            <div class="explore_img">
              <img
                src="./assets/Images/explore-img.svg"
                alt="Explore_image"
              />
            </div>
            <div>
              <p class="fs16 fw600 primary_txt mb-3">
                Want to find more you are looking for?
              </p>
              <button type="button" class="fs14 fw600 d-flex" (click)="navigateToCareer(path)">
                Explore GSG Careers
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="my_activites mb-4">
        <h4 class="fs22 fw600 primary_txt mb-4">My Activities</h4>
        <div class="activities-grid">
          <div class="bg-white activites_cards">
            <div class="pad_10_16">
              <h4 class="fs18 fw600 primary_txt"> My Task ({{
                allTasksOriginalContent
                  ? (allTasksOriginalContent.count ? allTasksOriginalContent.count : allTasksOriginalContent.length)
                  : 0
              }})</h4>
            </div>
            <div class="bdr_btm"></div>
            <div class="pad_10_16">
              <div class="tabs-search-container mb-3">
                <p-tabView
                  [(activeIndex)]="activeTabIndexTaskDetails"
                  class="tabs-section" (onChange)="onMyActivitesTabChange(activeTabIndexTaskDetails)"
                >
                  <p-tabPanel
                    *ngFor="let tab of activitesTabs; let i = index"
                  >
                    <ng-template pTemplate="header">
                      <span>{{ tab.title }} </span>
                      <span class="tabs_count">{{ tab.count }} </span>
                    </ng-template>
                  </p-tabPanel>
                </p-tabView>

                <div class="search-inputt relative">
                  <input
                    type="text"
                    class="w-100"
                    placeholder="Search"
                    [(ngModel)]="searchTaskItem"
                    (input)="searchTemplates()"
                  />
                  <img
                    class="img-fluid absolute search-icon"
                    src="./assets/Images/search.svg"
                    alt="search icon"
                  />
                  <svg
                    (click)="clearSearch()"
                    *ngIf="searchTaskItem"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="cross-iconn pointer bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </div>
              </div>

              <div class="custom_scroll pad_20_22">
                <ng-container *ngIf="
                activitesTabs[activeTabIndexTaskDetails]?.filteredContent?.length > 0;else noMyTaskDataTemplate"
              class="pad_20_22">
                  <div
                    *ngFor="
                      let task of activitesTabs[activeTabIndexTaskDetails]
                        .filteredContent
                    "
                    class="task-item d-flex justify-content-between mb-2 pb-1"
                  >
                    <div class="d-flex align-items-center">
                      <div class="task-icon mr-2">
                        <img
                          src="./assets/Images/Mention-mytasks.svg"
                          alt="mention"
                        />
                      </div>
                      <div class="task-info">
                        <div class="d-flex align-items-center">
                          <div>
                            <h6 class="fs14 fw600 primary_txt mb-1">
                              {{ task.message  }}
                            </h6>
                            <div class="d-flex">
                              <span class="fs12 fw400 primary_txt mr-1">
                                {{ task.miniMessage}}
                                <img (click)="routeToInterviews(task.message)"
                                src="./assets/Images/mytasks-send.svg"
                                alt="send" class="ml-2 pointer"
                              />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="task-time">
                      <span class="fs12 fw400 primary_txt"
                        >{{ task.date }} | {{ task.time }}</span
                      >
                    </div>
                  </div>
              </ng-container>
                <ng-template #noMyTaskDataTemplate>
                  <div class="text-center p-3">{{emptyTaskMessage ? emptyTaskMessage : 'No tasks found'}}.</div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="bg-white activites_cards">
            <div class="pad_10_16 d-flex align-items-center justify-content-between">
              <h4 class="fs18 fw600 primary_txt">My Interviews</h4>

              <!-- <div *ngIf="activeTab?.content?.data?.length > 5">
              <button type="button" class="view_all fs14 fw600" (click)="toggleViewAll()">{{ showAllInterviews ? 'View Less' : 'View All' }}</button>
            </div> -->
            <button type="button" class="view_all fs14 fw600" (click)="redirectToInterviews()">View All</button>

            </div>
            <div class="bdr_btm"></div>
            <div class="pad_10_16">
              <div class="tabs-search-container mb-3 d-flex align-items-center justify-content-between flex-wrap">
                <p-tabView [(activeIndex)]="activeTabIndexInterviewDetails" class="tabs-section" (onChange)="onTabChange($event)">
                  <p-tabPanel *ngFor="let tab of interviewsTabs; let i = index">
                    <ng-template pTemplate="header">
                      {{ tab.title }}
                      <span class="tabs_count">{{ tab.count }}</span>
                    </ng-template>
                  </p-tabPanel>
                </p-tabView>

                <div class="search-inputt relative ml-3">
                  <input
                    type="text"
                    class="w-100"
                    placeholder="Search"
                    [(ngModel)]="searchitem"
                    (input)="searchInterviews($event)"
                  />
                  <img class="img-fluid absolute search-icon" src="./assets/Images/search.svg" alt="search icon" />
                  <svg
                    *ngIf="searchInterviewItem && searchitem.length > 0"
                    (click)="clearInterviewSearch()"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="cross-iconn pointer bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </div>
              </div>

              <div  class="custom_scroll pad_20_22">
                <ng-container *ngIf="activeTab?.content?.data?.length > 0; else noDataTemplate">
                  <div *ngFor="let interview of getDisplayedInterviews()">
                    <div class="d-flex align-items-center justify-content-between mb-2 pb-1" style="gap: 10px;">
                      <div class="d-flex align-items-center">
                        <div class="task-icon mr-2">
                          <img src="./assets/Images/interviews-logo.svg" alt="mention" />
                        </div>
                        <div class="applicant_style width-max">
                          <h6 class="fs14 fw600 primary_txt mb-1">{{ interview?.applicantName }}</h6>
                          <div class="fs12 fw400 primary_txt">
                            {{ interview?.dateRange | date:'dd-MM-yyyy' }} | {{ getFormattedTime(interview?.meetingTIme) }}
                          </div>
                        </div>
                      </div>
                      <div class="stage_campus_style">
                        <div class="d-flex align-items-center">
                          <span class="fs12 fw500 light_grey mr-1 mb-1 stage_wrap" pTooltip="{{ interview?.stage }}" tooltipPosition="bottom"
                          >Stage: {{ interview?.stage }}
                        </span>

                            <span class="fs12 fw500 light_grey mb-1" [ngClass]="{
                              'status-completed': interview?.applicantStatus === 'Completed',
                              'Pending': interview?.applicantStatus === 'Pending',
                              'status-selected': interview?.applicantStatus === 'Selected',
                              'status-Rejected ': interview?.applicantStatus === 'Rejected'
                            }">
                              {{interview?.applicantStatus}}
                            </span>
                            <span class="fs12 fw500 light_grey mr-1 mb-1">{{ interview?.interviewType }}</span>

                        </div>
                        <div class="fs12 fw400 primary_txt d-flex align-items-center">
                          <span class="" pTooltip="{{ interview?.campus }}" tooltipPosition="bottom">{{ interview?.campus }}</span>
                          <span class="jobtitle_wrap"  pTooltip="{{ interview?.jobTitle }}" tooltipPosition="bottom">{{ interview?.jobTitle }}</span>
                          <span style="padding-left: 7px;">
                           | {{ interview?.jobCode }}
                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-template #noDataTemplate>
                  <div class="text-center p-3">No interviews found.</div>
                </ng-template>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="my_referals bg-white mb-4 pb-2 w-100">
        <div class="text-center">
          <img
            src="../../../assets/Images/no-referals.svg"
            alt="no_referals"
          />
          <p class="fs18 fw600 primary_txt my-1">My Referal</p>
          <p class="fs14 fw600" style="color: #4b5565">
            Upcoming...
          </p>
        </div>
      </div>
</ng-container>
          <div>
            <router-outlet></router-outlet>
          </div>

        <!-- </p-tabPanel>
      </p-tabView> -->
    </div>
  </section>
</main>
